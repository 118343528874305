import React from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { i18n } from 'Language';
import UITooltip from '../Vega/Tooltip';

type Props = {
    onClick: () => void;
    isCollapsed?: boolean;
    showTooltip?: boolean;
    isMobile?: boolean;
    displaySigner?: boolean;
};
const BurgerMenuButton = ({
    onClick,
    isCollapsed,
    showTooltip,
    isMobile,
    displaySigner,
}: Props) => {
    const canShowMenuButton = isCollapsed || (!isCollapsed && !isMobile);
    const canShowBackground =
        (isMobile && !displaySigner) ||
        (!isMobile && displaySigner && !isCollapsed);
    const iconColor =
        (!displaySigner && isMobile) ||
        (displaySigner && !isMobile && !isCollapsed)
            ? 'text-[#086AFA]'
            : 'text-[#04224C]';

    return (
        <button
            aria-label={isCollapsed ? i18n('showMenu') : i18n('hideMenu')}
            onClick={onClick}
            className={`w-[44px] h-[44px] bg-[transparent] active:bg-[transparent] appearance-none border-none cursor-pointer absolute top-[0.3rem] ${
                isMobile ? 'left-[0.8rem]' : 'left-[1rem]'
            } z-[9999] flex items-center justify-center`}>
            {canShowBackground && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div
                        className="w-[80%] h-[80%] bg-[#E1F1FF] rounded-sm"
                        aria-hidden="true"
                    />
                </div>
            )}
            <div
                className={`relative w-[24px] h-[24px] rounded-sm ${iconColor}`}>
                {canShowMenuButton && (
                    <Bars3Icon
                        data-testid="bars3-icon"
                        className={`w-full h-full}`}
                        strokeWidth={2}
                    />
                )}
                {!canShowMenuButton && (
                    <XMarkIcon
                        data-testid="xmark-icon"
                        className={`w-full h-full`}
                    />
                )}
            </div>
            {showTooltip && (
                <UITooltip data-testid="tooltip" placement="right" offset={7}>
                    {isCollapsed ? i18n('showMenu') : i18n('hideMenu')}
                </UITooltip>
            )}
        </button>
    );
};

export default BurgerMenuButton;
