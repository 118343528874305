import React from 'react';
import '../../signing-done-lead-gen.scss';
import '../../signing-footer-lead-gen.scss';
type Props = {
    title: string;
    description?: string;
    info?: string;
};

export const InfoSection = ({ title, description, info }: Props) => {
    return (
        <section className="landing-page-info-section">
            <div className="title">{title}</div>
            {description && <div className="description">{description}</div>}
            {info && <div className="info-text text-center">{info}</div>}
        </section>
    );
};
