import React from 'react';
import { i18n } from 'Language';
import { CaseFileItem } from '..';
import { useAppSelector } from 'Store';

export type Props = {
    item: CaseFileItem;
    userId: number;
};

const CaseFileTypeItem = ({ item, userId }: Props) => {
    const folderOwnerId = useAppSelector(
        (state) => state.archive.folderOwnerId
    );

    if (item.type === 'folder') {
        if (!item.shared) {
            return <span>{i18n('Folder')}</span>;
        }

        const isOwner = item.userId === userId || folderOwnerId === userId;

        return <span>{isOwner ? i18n`Shared folder` : `Shared with me`}</span>;
    }

    if (!item.caseFileType) {
        return <span>- - -</span>;
    }

    return (
        <span className="no-wrap">
            <i className="far fa-tag" style={{ marginRight: '0.3em' }} />
            {item.caseFileType.name}
        </span>
    );
};

export default CaseFileTypeItem;
