import { useEffect, useState } from 'react';
import {
    fetchSummary,
    fetchIncidentIntervalDuration,
} from 'Common/components/StatusPageMessage/status-page-api';
import { Incident } from 'Common/components/StatusPageMessage';

export const useFetchIncidents = () => {
    const [incidents, setIncidents] = useState<Incident[]>([]);

    useEffect(() => {
        let fetchInterval: NodeJS.Timeout;
        let isMounted = true;

        const fetchIncidents = async () => {
            const incidentData = await fetchSummary();

            if (isMounted) {
                setIncidents(incidentData);
            }
        };

        fetchIncidents();
        fetchInterval = setInterval(
            fetchIncidents,
            fetchIncidentIntervalDuration
        );

        return () => {
            isMounted = false;
            clearInterval(fetchInterval);
        };
    }, []);

    return incidents;
};
