import axios, { AxiosResponse } from 'axios';
import uniqid from 'uniqid';
import { Dispatcher } from 'Core';
import { SigningAPI } from 'Api';
import { Integrations, TEMPLATE_ENTITY_NAME } from 'Constants';
import Constants from '../Constants';
import lodash from 'lodash';
import assign from 'object-assign';
import moment from 'moment';
import SignerActions from './SignerActionCreators';
import DocumentActions from './DocumentActionCreators';
import Analytics from 'Common/Analytics';
import { TemplateType } from 'types/EmailTemplates';
import { CaseFileEntity } from 'types/CaseFile';
import { SignerEntity, SigningRequestEntity } from 'types/Signer';
import { env } from 'Constants';
import { parseURIString } from 'Casefiles/utils/uri';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import store, { dispatch } from 'Store';
import CasefileStore from 'Casefiles/stores/CasefileStore';
import { NewCasefileState } from 'Casefiles/redux/newCaseFile/types';
import { insecureSigningMethodsDefault } from 'EID/types';
import {
    fetchTemplateEntities,
    updateTemplateEntities,
    deleteTemplateEntity,
    replaceTemplateEntity,
    isCasefileOwner,
} from 'Casefiles/utils';
import CustomerStore from 'Auth/stores/CustomerStore';
import { AuthStore } from 'Auth';
import { CustomerEntity } from 'types/Customer';
import { UserSettingKeys } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import debug from 'debug';
import { getTemporarySendAt } from 'Casefiles/components/casefiles2/utils';
import { saveRoundsActivation } from 'Casefiles/components/casefiles2/CasefileRounds/utils';
import { Languages } from 'Language/Constants';
import {
    fetchLanguage,
    LocalizedDefaultEmailMessage,
} from 'Casefiles/utils/localeTemplates';

type IntegrationURIPayload = {
    item: string;
};

const CaseFileActions = {
    // *************************************************************************
    // GET REQUESTS
    // *************************************************************************

    fetchCasefileTypes() {
        return SigningAPI.get('/casefile/casefiletypes').then(
            (casefileTypes) => {
                this.setCasefileTypes(casefileTypes);
            }
        );
    },

    fetchFolders(permissions = 'read-write') {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_FOLDERS_REQUEST,
        });

        let query = {
            permissions: permissions,
        };

        return SigningAPI.get('/folders', query).then((folders) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_FOLDERS_SUCCESS,
                folders: folders,
            });
        });
    },

    fetchDefaultFolder() {
        return SigningAPI.get('/folders/default').then((folder) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_DEFAULT_FOLDER_SUCCESS,
                folderId: folder.id,
            });

            return folder;
        });
    },

    async _fetchSigningRequests(
        casefileId: number,
        signers: (SignerEntity & { tempId: string })[]
    ) {
        const promises = signers.map((signer) =>
            this._fetchSigningRequest(casefileId, signer.id)
        );

        try {
            const responses: SigningRequestEntity[][] = await Promise.all(
                promises
            );

            // Flatten signing requests
            const signingRequests = ([] as SigningRequestEntity[]).concat(
                ...responses
            );

            // Merge tempIds from the locally stored signers with fetched
            // signing requests. TempIds lets us group the correct signer
            // with the correct email message.
            return signingRequests.map((signingRequest, index) => ({
                ...signingRequest,
                tempId: signers[index].tempId,
            }));
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                error: error,
            });

            throw error;
        }
    },

    _fetchSigningRequest(casefileId, signerId) {
        return SigningAPI.get(
            `/casefiles/${casefileId}/signers/${signerId}/signingrequests`
        );
    },

    // *************************************************************************
    // POST REQUESTS
    // *************************************************************************
    _createCasefile(data) {
        // If casefile metadata is not set, use the platform (desktop | web).
        if (!data.metaData) {
            data.metaData = env.platform;
        }

        return SigningAPI.post('/casefiles', data);
    },

    _linkFolder(casefileId, folderId) {
        return SigningAPI.post(`/folders/${folderId}/casefiles/${casefileId}`);
    },

    _addDocuments(caseFileId, documents) {
        let _promises: any[] = [];

        documents.forEach((doc) => {
            _promises.push(this._addDocument(doc, caseFileId));
        });

        // If all the documents aren't posted successfully to the casefile, we return an array of documentIDs to rollback the operation
        // to free up the linked preview files

        // Custom Promise.allSettled implementation (IE11 doesn't support allSettled and it's not available through our
        // babel library without updates)
        // @see: https://stackoverflow.com/a/39031032
        function allSettled(promises: any[]) {
            let wrappedPromises = promises.map((p) =>
                Promise.resolve(p).then(
                    (val) => ({ status: 'fulfilled', value: val }),
                    (err) => ({ status: 'rejected', reason: err })
                )
            );

            return Promise.all(wrappedPromises);
        }

        return allSettled(_promises).then((results) => {
            if (results.every((result) => result.status === 'fulfilled')) {
                return results.map((result: any) => result.value);
            }

            /* eslint no-throw-literal:0 */
            throw {
                successfulDocuments: results.filter(
                    (result) => result.status === 'fulfilled'
                ),
                erroredDocuments: results.filter(
                    (result) => result.status !== 'fulfilled'
                ),
            };
        });
    },

    async _addDocument(doc, casefileId) {
        const payload = {
            caseFileId: casefileId,
            fileId: doc.id,
            title: doc.name,
            documentTypeId: doc.documentTypeId,
            documentOrder: doc.order,
            metaData: doc.metaData,
        };

        const conditionalSigningOptions = CasefileStore.getConditionalSigningOptions();

        if (conditionalSigningOptions.enabled) {
            payload['opts'] = { ...doc.opts, conditionalSigningEnabled: true };
        } else {
            payload['opts'] = { ...doc.opts, conditionalSigningEnabled: false };
        }

        try {
            const response = await SigningAPI.post(
                '/document-from-file',
                payload
            );

            Analytics.track('casefile - document upload success', {
                documentId: response.id,
                caseFileId: casefileId,
                fileId: doc.id,
            });

            return response;
        } catch (error) {
            Analytics.track('casefile - document upload error', {
                statusCode: error.status,
            });

            throw this.CasefileException({
                error: error,
                payload: payload,
                sourceType: 'DOCUMENT',
            });
        }
    },

    _addSigners(casefileId, signers) {
        let _promises: any[] = [];

        signers.forEach((signer) => {
            _promises.push(this._addSigner(casefileId, signer));
        });

        return Promise.all(_promises);
    },

    /*
     * TODO: If _linkSignerType fails, it throws an error that would be
     * overwritten by the error thrown at the end this function's sequence. This
     * should be forked to support both errors being picked up.
     */
    _addSigner(casefileId, signer) {
        const ssnType = signer.ssn ? signer.ssnType : 'legacy';

        let payload = {
            name: signer.name,
            socialSecurityNumberPlain: signer.ssn,
            vatin: signer.vatin,
            onBehalfOf: signer.onBehalfOf,
            storeAsContact: signer.storeAsContact,
            ssnType: ssnType,
            language: signer.language,
        };

        return SigningAPI.post(
            `/casefiles/${casefileId}/signers`,
            payload
        ).then((response) => {
            let _promises = signer.role
                .filter((role) => role.enabled)
                .map((role) => {
                    return this._linkSignerType(casefileId, response.id, role);
                });

            return Promise.all(_promises)
                .then(() => {
                    return { ...signer, id: response.id };
                })
                .catch((error) => {
                    throw this.CasefileException({
                        error: error,
                        payload: payload,
                        sourceType: 'SIGNER',
                    });
                });
        });
    },

    _linkSignerType(casefileId, signerId, signerType) {
        let payload: any = {};
        let endpoint = `/casefiles/${casefileId}/signers/${signerId}/signertypes/${signerType.id}`;

        // Set new data (if any)
        const { customName, activeAt } = signerType;

        payload = {
            ...(customName && { role: customName }),
            ...(activeAt && { activeAt }),
        };

        // @todo: add support for expiration/send dates

        return SigningAPI.post(endpoint, payload).catch((error) => {
            throw this.CasefileException({
                error: error,
                payload: payload,
                sourceType: 'SIGNER_TYPE',
            });
        });
    },

    _addCopyRecipients(casefileId, recipients) {
        let _promises: any[] = [];

        recipients.forEach((recipient) => {
            _promises.push(this._addCopyRecipient(casefileId, recipient));
        });

        return Promise.all(_promises);
    },

    _addCopyRecipient(casefileId, recipient) {
        if (recipient.name === null && recipient.email === null) {
            return Promise.resolve();
        }

        return SigningAPI.post(
            `/casefiles/${casefileId}/recipients`,
            recipient
        ).catch((error) => {
            throw this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    // *************************************************************************
    // PUT REQUESTS
    // *************************************************************************

    _updateCasefile(casefile) {
        let updatedCasefile = assign({}, casefile);

        delete updatedCasefile.id;
        delete updatedCasefile.createdAt;

        return SigningAPI.put(`/casefiles/${casefile.id}`, updatedCasefile);
    },

    _updateFolder(casefileId, newFolderId, prevFolderId) {
        let promises: any[] = [];

        if (newFolderId === prevFolderId) {
            return Promise.resolve();
        }

        if (newFolderId) {
            promises.push(this._linkFolder(casefileId, newFolderId));
        }

        if (typeof prevFolderId !== 'undefined') {
            promises.push(this._unlinkFolder(casefileId, prevFolderId));
        }

        return Promise.all(promises);
    },

    _updateDocuments(caseFileId, documents, fetchedDocuments) {
        let documentsToDelete = lodash.differenceBy(
            fetchedDocuments,
            documents,
            'id'
        );

        let _promises = documents.map((doc) => {
            if (doc.caseFileId === caseFileId) {
                return this._updateDocument(doc);
            }

            return this._addDocument(doc, caseFileId);
        });

        _promises = _promises.concat(
            documentsToDelete.map((doc) => this._deleteDocument(doc.id))
        );

        return Promise.all(_promises);
    },

    async _updateDocument(doc) {
        if (doc.status !== 0) {
            // @todo: handle this error
            throw Error(
                `The file can not be persisted, its status is: ${doc.status}`
            );
        }

        const payload = {
            title: doc.name,
            ...(doc.documentTypeId && { documentTypeId: doc.documentTypeId }),
            ...(doc.opts && { opts: doc.opts }),
            ...(doc.metaData && { metaData: doc.metaData }),
            ...(doc.options && { options: doc.options }),
            ...(doc.order && { documentOrder: doc.order }),
        };

        try {
            return await SigningAPI.put(`/documents/${doc.id}`, payload);
        } catch (error) {
            Analytics.track('casefile - document update error', {
                statusCode: error.status,
            });

            throw this.CasefileException({
                error: error,
                payload: payload,
                sourceType: 'DOCUMENT',
            });
        }
    },

    _updateSigners(casefileId, signers, fetchedSigners) {
        let signersToDelete = lodash.differenceBy(
            fetchedSigners,
            signers,
            'id'
        );

        let _promises = signers.map((signer) => {
            if (signer.id) {
                return this._updateSigner(casefileId, signer, fetchedSigners);
            }

            return this._addSigner(casefileId, signer);
        });

        _promises = _promises.concat(
            signersToDelete.map((signer) =>
                this._deleteSigner(casefileId, signer.id)
            )
        );

        return Promise.all(_promises);
    },

    _updateSigner(casefileId, signer, fetchedSigners) {
        const ssnType = signer.ssn ? signer.ssnType : 'legacy';

        let payload = {
            name: signer.name,
            socialSecurityNumberPlain: signer.ssn,
            vatin: signer.vatin,
            onBehalfOf: signer.onBehalfOf,
            storeAsContact: signer.storeAsContact,
            ssnType: ssnType,
            language: signer.language,
        };

        const findRoleAmongFetched = (role) =>
            fetchedRoles.find(({ id }) => id === role.id);
        const isRoleAmongFetched = (role) => !!findRoleAmongFetched(role);
        const isRoleNotAmongFetched = lodash.negate(isRoleAmongFetched);

        // Signer roles that has been fetched from the database must be compared with
        // the current roles that are in the Store, it's due to determining which roles are new
        // and should be persisted and which should be deleted.
        let fetchedRoles = fetchedSigners.find((obj) => obj.id === signer.id)
            .roles;

        // Roles that the user selected
        const enabledRoles = signer.role.filter((role) => role.enabled);

        // Roles to persist are the ones that are not among the fetched ones
        // and have enabled set to true
        let rolesToPersist = enabledRoles.filter(isRoleNotAmongFetched);

        // Roles to update are the ones that are among the fetched ones, that
        // are still enabled, and that have had their custom name amended
        const rolesToUpdate = enabledRoles
            .filter(isRoleAmongFetched)
            .filter((role) => {
                const fetchedRole = findRoleAmongFetched(role);

                return (
                    fetchedRole?.customName !== role?.customName ||
                    fetchedRole?.activeAt !== role?.activeAt
                );
            });

        // Roles to delete are the ones that are among the fetched ones
        // but are not selected as signer's current roles
        let signerRoles = signer.role.map((role) => role.id);
        let rolesToDelete = fetchedRoles.filter(({ id }) => {
            return signerRoles.indexOf(id) === -1;
        });

        // Update the signer
        return SigningAPI.put(
            `/casefiles/${casefileId}/signers/${signer.id}`,
            payload
        ).then((response) => {
            let _promises = rolesToPersist.map((role) => {
                return this._linkSignerType(casefileId, response.id, role);
            });

            _promises = _promises.concat(
                rolesToUpdate.map((role) => {
                    return this._linkSignerType(casefileId, response.id, role);
                })
            );

            _promises = _promises.concat(
                rolesToDelete.map((role) => {
                    return this._unlinkSignerType(
                        casefileId,
                        response.id,
                        role
                    );
                })
            );

            return Promise.all(_promises)
                .then(() => {
                    return signer;
                })
                .catch((error) => {
                    throw this.CasefileException({
                        error: error,
                        payload: payload,
                        sourceType: 'SIGNER',
                    });
                });
        });
    },

    _updateCopyRecipients(casefileId, recipients, fetchedCopyRecipients) {
        let recipientsToDelete = lodash.differenceBy(
            fetchedCopyRecipients,
            recipients,
            'id'
        );

        let _promises = recipients.map((recipient) => {
            if (recipient.id) {
                return this._updateCopyRecipient(casefileId, recipient);
            }

            return this._addCopyRecipient(casefileId, recipient);
        });

        _promises = _promises.concat(
            recipientsToDelete.map((recipient) => {
                return this._deleteCopyRecipient(casefileId, recipient.id);
            })
        );

        return Promise.all(_promises);
    },

    _updateCopyRecipient(casefileId, recipient) {
        if (recipient.name === null && recipient.email === null) {
            return Promise.resolve();
        }

        let payload = {
            name: recipient.name,
            email: recipient.email,
        };

        let endpoint = `/casefiles/${casefileId}/recipients/${recipient.id}`;

        return SigningAPI.put(endpoint, payload).catch((error) => {
            throw this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    async _editSigningReqMultipleEmails(
        signingRequests: SigningRequestEntity[],
        emailMessages: NewCasefileState['emailMessages'],
        signers: (SignerEntity & { tempId: string })[]
    ) {
        const fetchLanguageTemplates = async (
            langs: Languages[]
        ): Promise<Map<Languages, LocalizedDefaultEmailMessage>> => {
            const langTemplates = langs.map(fetchLanguage);
            const items = await Promise.all(langTemplates);

            return new Map(items.map((item) => [item.language, item]));
        };

        const chosenLanguages = signers
            .map((item) => item.language)
            .filter((language) => language !== undefined);

        const localizedTemplates = chosenLanguages
            ? await fetchLanguageTemplates(chosenLanguages as Languages[])
            : undefined;

        const getSignerLanguageTemplate = (lang: Languages) => {
            const localeTemplate = localizedTemplates!.get(lang);

            return lodash.omit(localeTemplate, ['language']);
        };

        return Promise.all(
            signers.map((signer, index) => {
                const customEmailMessage = emailMessages.custom.find(
                    (message) =>
                        message.recipients.find(
                            (recipient) => recipient.tempId === signer.tempId
                        )
                );

                const recipientMessages = customEmailMessage
                    ? customEmailMessage.messages
                    : emailMessages.general.messages;

                const signRequestMessage = recipientMessages![
                    TemplateType.SIGNER
                ];

                // For the 'completed' and 'reminder' types of messages, we
                // always use the casefile-specific messages
                const completedMessage = emailMessages.general.messages![
                    TemplateType.COMPLETED
                ];
                const reminderMessage = emailMessages.general.messages![
                    TemplateType.REMINDER
                ];

                const hasSignerLanguage = localizedTemplates?.has(
                    signer.language as Languages
                );

                const parsedMessages = hasSignerLanguage
                    ? {
                          ...getSignerLanguageTemplate(
                              signer.language as Languages
                          ),
                      }
                    : {
                          completedEmailSubject:
                              completedMessage.subject.content,
                          completedEmailText: completedMessage.body.content,
                          emailSubject: signRequestMessage.subject.content,
                          emailText: signRequestMessage.body.content,
                          reminderEmailSubject: reminderMessage.subject.content,
                          reminderEmailText: reminderMessage.body.content,
                      };

                return this._editSigningRequest(
                    signingRequests[index].id,
                    parsedMessages,
                    signer
                );
            })
        );
    },

    // TODO: This should be revised and potentially removed once the flag
    // SIGNER_SPECIFIC_EMAILS is permanently turned on for all users
    _editSigningReqOneEmail(signingRequests, emailTemplates, signers) {
        let _promises: any[] = [];

        signers.forEach((signer, index) => {
            _promises.push(
                this._editSigningRequest(
                    signingRequests[index].id,
                    emailTemplates,
                    signer
                )
            );
        });

        return Promise.all(_promises);
    },

    _editSigningRequest(signingRequestId, emailTemplates, signer) {
        const payload = {
            email: signer.email,
            reminderInterval: signer.reminderInterval,
            enableInsecureSigning: signer.enableInsecureSigning,
            accessControl: signer.accessControl,
            insecureSigningMethods: signer.insecureSigningMethods,
            ...emailTemplates,
        };

        return SigningAPI.put(`/signingrequests/${signingRequestId}`, payload);
    },

    // *************************************************************************
    // DELETE REQUESTS
    // *************************************************************************

    _unlinkFolder(casefileId, folderId) {
        return SigningAPI.delete(
            `/folders/${folderId}/casefiles/${casefileId}`
        );
    },

    _deleteDocument(documentId) {
        // We avoid calling the API if we don't have a valid doc id
        if (!documentId) {
            return;
        }

        return SigningAPI.delete(`/documents/${documentId}`)
            .then((response) => {
                // Dispatch an action to remove the documents from `_fetchedCasefileData` in CasefileStore.js
                // This is to avoid problems when retrying to submit a casefile.
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.DOCUMENT_DELETE_SUCCESS,
                    documentId: documentId,
                });

                return response;
            })
            .catch((error) => {
                throw this.CasefileException({
                    error: error,
                    sourceType: 'DOCUMENT',
                });
            });
    },

    _deleteSigner(casefileId, signerId) {
        return SigningAPI.delete(
            `/casefiles/${casefileId}/signers/${signerId}`
        ).catch((error) => {
            throw this.CasefileException({
                error: error,
                sourceType: 'SIGNER',
            });
        });
    },

    _unlinkSignerType(casefileId, signerId, signerType) {
        let endpoint = `/casefiles/${casefileId}/signers/${signerId}/signertypes/${signerType.id}`;

        return SigningAPI.delete(endpoint).catch((error) => {
            throw this.CasefileException({
                error: error,
                sourceType: 'SIGNER_TYPE',
            });
        });
    },

    _deleteCopyRecipient(casefileId, recipientId) {
        return SigningAPI.delete(
            `/casefiles/${casefileId}/recipients/${recipientId}`
        ).catch((error) => {
            throw this.CasefileException({
                error: error,
                sourceType: 'COPY_RECIPIENT',
            });
        });
    },

    // *************************************************************************
    // PATCH REQUESTS
    // *************************************************************************

    _sendSigningRequest(casefileId) {
        return SigningAPI.patch(`/casefiles/${casefileId}/send`);
    },

    // *************************************************************************
    // REQUEST CHAINING
    // *************************************************************************

    async sendCasefile(data) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.CREATE_CASEFILE_REQUEST,
        });

        let documents: any[] = [];

        try {
            var casefile = await this._createCasefile(data.casefile);
            var signers = await this._addSigners(casefile.id, data.signers);

            // Save rounds activation date
            await saveRoundsActivation(data.rounds);

            await this._linkFolder(casefile.id, data.selectedFolderId);

            await this._addCopyRecipients(casefile.id, data.recipients);

            const signingRequests = await this._fetchSigningRequests(
                casefile.id,
                signers
            );

            await this._setupSigningRequests(signingRequests, {
                signers: signers,
                emailMessages: data.emailMessages,
            });

            documents = await this._addDocuments(casefile.id, data.documents);

            await this._sendSigningRequest(casefile.id);

            // Track signer added.
            signers.map(prepareSignerDataForTracking).forEach((signer) => {
                Analytics.track('casefile - signer added', {
                    casefileId: casefile.id,
                    ...signer,
                });
            });

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_SUCCESS,
                casefile: casefile,
            });
        } catch (error) {
            // If a casefile creation fails, before allowing the user to retry, we have to delete the casefile
            // that was created along with documents and not sent to:
            // 1 - Avoid creating a casefile draft every single time a casefile fails.
            // 2 - Free the previewable files to be attached to the new document resources that get created when
            // a user retries to send a casefile. (Files can only be associated to a single document at a time)

            // If the error occurs when posting the documents, the error object will contain the properties:
            // (erroredDocuments, successfulDocuments) and we need to roll back all documents that were successful.

            // If the casefile creation error occurred after the documents were created, all the successful
            // documents must be deleted (from the `documents` variable) and won't be part of the error object.
            const rollbackDocuments = error.successfulDocuments || documents;

            if (rollbackDocuments) {
                await Promise.all(
                    rollbackDocuments.map((doc) =>
                        SigningAPI.delete(`/documents/${doc.id}`)
                    )
                );
            }

            if (casefile && casefile.id) {
                await SigningAPI.delete(`/casefiles/${casefile.id}`);
                // Casefiles must be force deleted from the trash to avoid populating the recycle bin or creating a
                // draft every time creation fails.
                await SigningAPI.post(
                    `/trash/casefiles/${casefile.id}/forcedelete`
                );
            }

            const isClientSelectorFeatureEnabled: boolean = LaunchDarkly.variation(
                Flags.EXPERIMENT_CLIENT_SELECTOR_ENABLED
            );

            const {
                auditingAccounting: { selectedClient, addedRecipientsCount },
            } = store.getState();

            Analytics.track('casefile - creation error', {
                caseFileId: casefile?.id,
                ...(isClientSelectorFeatureEnabled && {
                    clientVatin: selectedClient?.vatin,
                    signersAddedViaSuggestion: addedRecipientsCount,
                }),
            });

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                error: error,
            });
        }
    },

    _setupCasefile(casefileId, data) {
        // Setup Casefile Information
        const promises = [
            this._addSigners(casefileId, data.signers),
            this._addDocuments(casefileId, data.documents),
            this._linkFolder(casefileId, data.selectedFolderId),
            this._addCopyRecipients(casefileId, data.recipients),
            // Save rounds activation date
            saveRoundsActivation(data.rounds),
        ];

        return Promise.all(promises).then((response) => {
            return {
                signers: response[0] as (SignerEntity & { tempId: string })[],
                documents: response[1],
                folder: response[2],
                ccRecipients: response[3],
            };
        });
    },

    async sendUpdatedCasefile(casefileData, fetchedCasefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.CREATE_CASEFILE_REQUEST,
        });

        try {
            const { casefile, signers } = await this._setupUpdatedCasefile(
                casefileData,
                fetchedCasefileData
            );

            const signingRequests = await this._fetchSigningRequests(
                casefile.id,
                signers
            );

            await this._setupSigningRequests(signingRequests, {
                signers: signers,
                emailMessages: casefileData.emailMessages,
            });

            await this._sendSigningRequest(casefile.id);

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_SUCCESS,
                casefile: casefile,
            });
        } catch (error) {
            Analytics.track('casefile draft - send error', {
                caseFileId: casefileData.casefile.id,
            });

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.CREATE_CASEFILE_FAILURE,
                error: error,
            });
        }
    },

    _setupUpdatedCasefile(casefileData, fetchedCasefileData) {
        return this._updateCasefile(casefileData.casefile).then(
            async (casefile) => {
                const updateFolderPromise = this._updateFolder(
                    casefile.id,
                    casefileData.selectedFolderId,
                    fetchedCasefileData.folderId
                );

                // deleting documents or signers in parallel with the folder
                // update request might cause it to fail, because it adds
                // those entities to the response, so we execute it first.
                await updateFolderPromise;

                let promises: any[] = [
                    this._updateSigners(
                        casefile.id,
                        casefileData.signers,
                        fetchedCasefileData.signers
                    ),
                    this._updateDocuments(
                        casefile.id,
                        casefileData.documents,
                        fetchedCasefileData.documents
                    ),
                    updateFolderPromise,
                    this._updateCopyRecipients(
                        casefile.id,
                        casefileData.recipients,
                        fetchedCasefileData.copyRecipients
                    ),
                    // Save rounds activation date
                    saveRoundsActivation(casefileData.rounds),
                ];

                return Promise.all(promises).then((response) => {
                    return {
                        casefile: casefile as CaseFileEntity,
                        signers: response[0].filter(
                            (signer) => signer
                        ) as (SignerEntity & { tempId: string })[], // filter out falsy values
                        documents: response[1].filter((doc) => doc), // filter out falsy values
                        folder: response[2],
                        ccRecipients: response[3],
                    };
                });
            }
        );
    },

    async updateCasefileDraft(casefileData, fetchedCasefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SAVE_DRAFT_REQUEST,
        });

        try {
            const casefileResponse = await this._setupUpdatedCasefile(
                casefileData,
                fetchedCasefileData
            );

            const signingRequests = await this._fetchSigningRequests(
                casefileResponse.casefile.id,
                casefileResponse.signers
            );

            await this._setupSigningRequests(signingRequests, casefileData);

            // If the signer-specific emails feature is enabled
            if (LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
                await updateTemplateEntities(
                    casefileResponse.casefile.id,
                    casefileData.emailMessages,
                    signingRequests.map((signingRequest) => ({
                        tempId: signingRequest.tempId,
                        signingRequestId: signingRequest.id,
                    }))
                );

                // Get signing request IDs for all signers that should recieve a
                // custom message
                const signingRequestIdsInCustomMessages = casefileData.emailMessages.custom
                    .map((message) =>
                        message.recipients.map(
                            (recipient) =>
                                signingRequests.find(
                                    (signingRequest) =>
                                        signingRequest.tempId ===
                                        recipient.tempId
                                )?.id
                        )
                    )
                    .flatten();

                // Get signing request IDs for all signers that should receive
                // the casefile specific message
                const signingRequestIdsUsingGeneral = signingRequests
                    .map((signingRequest) => signingRequest.id)
                    .filter(
                        (signingRequestId) =>
                            !signingRequestIdsInCustomMessages.includes(
                                signingRequestId
                            )
                    );

                /**
                 * 1. Check if there are any signing request IDs using the
                 * general message.
                 *
                 * 2. Retrieve all template entities associated with these
                 * signing requests (This may occur because the draft is
                 * re-saved after a recipient has been removed from a custom
                 * message)
                 *
                 * 3. Update the template entities to exclude these signing
                 * request IDs (or delete the entity if it no longer contains
                 * any signing request IDs)
                 * */
                if (signingRequestIdsUsingGeneral.length > 0) {
                    const templateEntities = await fetchTemplateEntities({
                        signingRequestIds: signingRequestIdsUsingGeneral,
                    });

                    // Update or delete the template entities as needed.
                    const updatePromises = templateEntities.templates.map(
                        (template) => {
                            const signingRequests =
                                template.entityRelations
                                    .find(
                                        (relation) =>
                                            relation.name ===
                                            TEMPLATE_ENTITY_NAME.SIGNING_REQUEST
                                    )
                                    ?.ids.filter(
                                        (id) =>
                                            !signingRequestIdsUsingGeneral.includes(
                                                id
                                            )
                                    ) || [];

                            return signingRequests.length > 0
                                ? replaceTemplateEntity(
                                      template.content,
                                      template.kind,
                                      TEMPLATE_ENTITY_NAME.SIGNING_REQUEST,
                                      signingRequests,
                                      template.uuid
                                  )
                                : deleteTemplateEntity(
                                      template.kind,
                                      template.uuid
                                  );
                        }
                    );

                    await Promise.all(updatePromises);
                }
            }

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_SUCCESS,
                id: casefileResponse.casefile.id,
            });

            return Promise.resolve();
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_FAILURE, // not registered in store yet
                error: error,
            });

            return Promise.reject(error);
        }
    },

    async createCasefileDraft(casefileData) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SAVE_DRAFT_REQUEST,
        });

        try {
            const casefile = await this._createCasefile(casefileData.casefile);
            const response = await this._setupCasefile(
                casefile.id,
                casefileData
            );

            const signingRequests = await this._fetchSigningRequests(
                casefile.id,
                response.signers
            );

            await this._setupSigningRequests(signingRequests, {
                signers: response.signers,
                emailMessages: casefileData.emailMessages,
            });

            if (LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
                await updateTemplateEntities(
                    casefile.id,
                    casefileData.emailMessages,
                    signingRequests.map((signingRequest) => ({
                        tempId: signingRequest.tempId,
                        signingRequestId: signingRequest.id,
                    }))
                );
            }

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_SUCCESS,
                id: casefile.id,
            });
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.SAVE_DRAFT_FAILURE, // not registered in store yet
                error: error,
            });
        }
    },

    _setupSigningRequests(
        signingRequests: SigningRequestEntity[],
        data: {
            signers: (SignerEntity & { tempId: string })[];
            emailMessages:
                | NewCasefileState['emailMessages'] // Used if the flag 'SIGNER_SPECIFIC_EMAILS' is turned on
                // Used if the flag 'SIGNER_SPECIFIC_EMAILS' is turned off
                | {
                      completedEmailSubject: string;
                      completedEmailText: string;
                      emailSubject: string;
                      emailText: string;
                      reminderEmailSubject: string;
                      reminderEmailText: string;
                  };
        }
    ) {
        if (data.signers.length < 0) {
            return;
        }

        if (LaunchDarkly.variation(Flags.SIGNER_SPECIFIC_EMAILS)) {
            return this._editSigningReqMultipleEmails(
                signingRequests,
                data.emailMessages as NewCasefileState['emailMessages'],
                data.signers
            );
        }

        return this._editSigningReqOneEmail(
            signingRequests,
            data.emailMessages as {
                completedEmailSubject: string;
                completedEmailText: string;
                emailSubject: string;
                emailText: string;
                reminderEmailSubject: string;
                reminderEmailText: string;
            },
            data.signers
        );
    },

    async fetchCasefileDraft(casefileId: number) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_CASEFILE_DRAFT_REQUEST,
        });

        let fetched = false;
        let error: Error | undefined;

        try {
            const casefile = await SigningAPI.get(`/casefiles/${casefileId}`);

            const isOwner = isCasefileOwner(casefile);

            if (!isOwner) {
                throw new Error('Unauthorized');
            }

            const enhancedCasefile = await this._fetchCasefileDetails(casefile);

            await this.distributeCasefileData(enhancedCasefile);

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_CASEFILE_DRAFT_SUCCESS,
                fetchedCasefileData: enhancedCasefile,
            });
        } catch (err) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FETCH_CASEFILE_DRAFT_FAILURE,
                error: err,
            });

            error = err;
        } finally {
            fetched = true;
        }

        return { fetched, error };
    },

    async fetchIntegrationData(payload: string) {
        const { item }: IntegrationURIPayload = JSON.parse(payload);

        if (!item) {
            return;
        }

        let response: AxiosResponse;

        try {
            const { FETCH_URL } = Integrations.Silverfin.getUrls();

            if (!FETCH_URL) {
                throw new Error('Fetch url missing');
            }

            // Fetching remote JSON data
            response = await axios.get(FETCH_URL, {
                withCredentials: true,
                params: {
                    item,
                },
            });
        } catch (error) {
            const {
                response: { status, data },
            } = error;

            Analytics.track('integration - import error', {
                statusCode: status,
                ...(data.message && { error: data.message }),
            });

            throw error;
        }

        const rawData = response.data;
        const parsedData = parseURIString(rawData);
        const documentsFailed = await this.distributeCasefileData(parsedData);

        Analytics.track('integration - import successful', {
            schemaVersion: rawData.schemaVersion,
            ...(rawData.integrationId && { source: rawData.integrationId }),
        });

        return documentsFailed;
    },

    getCaseFileRecipients({ signers = [], copyRecipients = [] }) {
        const mappedSigners = signers.map((signer) => ({
            ...(signer as Object),
            type: 'signer',
        }));

        const mappedCopyRecipients = copyRecipients.map((copyRecipient) => ({
            ...(copyRecipient as Object),
            type: 'copyrecipient',
        }));

        return [...mappedSigners, ...mappedCopyRecipients];
    },

    /**
     *
     * @param data It's either a data of a case file draft that arrives from the Penneo server or
     * a data of a case file loaded through an integration with a third party application
     */
    async distributeCasefileData(data) {
        const recipients = this.getCaseFileRecipients(data);

        // Add general info about the case file
        this.populateStore(data);

        // Penneo case file draft
        if (data.id) {
            DocumentActions.populateStore(data.documents);
            SignerActions.populateStore(recipients);

            return;
        }

        // Integration payload
        if (data.source) {
            const documentsFailed = await DocumentActions.uploadDocuments(
                data.documents,
                data.language
            );

            SignerActions.URIUpdateSignerStore(recipients);

            return documentsFailed;
        }
    },

    // Enhance Casefile and its components
    _fetchCasefileDetails(casefile: CaseFileEntity) {
        const promises = [
            this._getCasefileDetails(casefile),
            this._getDocuments(casefile.documents),
            this._getSigners(casefile.signers, casefile.id),
            this._getEmailTemplates(casefile.signers),
            this._getRecipients(casefile.id),
            this._getFolder(casefile.id),
            fetchTemplateEntities({
                casefileId: casefile.id,
                signingRequestIds: casefile.signers.map(
                    (signer) => signer.signingRequest.id
                ),
            }),
            getTemporarySendAt(casefile.id),
        ];

        return Promise.all(promises).then((casefileData) => {
            const [
                details,
                documents,
                signers,
                emailTemplates,
                copyRecipients,
                folderId,
                templateEntities,
                temporarySendAt,
            ] = casefileData;

            let _casefile = assign({}, details);

            _casefile.documents = documents;
            _casefile.signers = signers;
            _casefile.emailTemplates = emailTemplates;
            _casefile.copyRecipients = copyRecipients;
            _casefile.folderId = folderId;
            _casefile.templateEntities = templateEntities;
            _casefile.temporarySendAt = temporarySendAt;

            /**
             * If we have a temporary 'send at' date,
             * we process it and add it to the casefile.
             * NOTE: the value of temporarySendAt is a string
             * and has to be parsed as an integer to work in moment
             */
            if (temporarySendAt?.value) {
                _casefile.sendAt = moment.unix(parseInt(temporarySendAt.value));
            }

            return _casefile;
        });
    },

    _getCasefileDetails(casefile) {
        let casefileDetails: any = {
            id: casefile.id,
            name: casefile.title,
            templateId: casefile.caseFileTypeId,
            language: casefile.language,
            reference: casefile.reference,
            created: casefile.created,
            signOnMeeting: casefile.signOnMeeting,
            sensitiveData: casefile.sensitiveData,
            copyRecipients: casefile.copyRecipients,
            visibilityMode:
                casefile.visibilityMode === 0
                    ? Constants.VISIBILITY_MODES[0]
                    : Constants.VISIBILITY_MODES[1],
        };

        if (casefile.completed) {
            casefileDetails.completed = casefile.completed;
        }

        if (casefile.sendAt) {
            casefileDetails.sendAt = moment.unix(casefile.sendAt);
        }

        if (casefile.expireAt) {
            casefileDetails.expireAt = moment.unix(casefile.expireAt);
        }

        if (casefile.metaData) {
            casefileDetails.metaData = casefile.metaData;
        }

        return casefileDetails;
    },

    _getSigners(signers, casefileId) {
        if (signers.length === 0) {
            return signers;
        }

        /**
         * If enableInsecureSigning is on and there's no insecureSigningMethods array,
         * we must prefill that with all the default SES.
         * If flag is on, filter default insecure signing methods with company settings.
         * Otherwise, use them all.
         */
        let insecureMethods = insecureSigningMethodsDefault;

        const customer = CustomerStore.getCustomer(
            AuthStore.getAuthDetails().cid
        ) as CustomerEntity;

        if (customer) {
            const { allowedSimpleSigningMethods } = customer;

            if (
                LaunchDarkly.variation(Flags.SPLIT_INSECURE_SIGNING_METHODS) &&
                allowedSimpleSigningMethods.length
            ) {
                insecureMethods = allowedSimpleSigningMethods;
            }
        }

        // Enhance Signers, fetch details
        let _signers: any[] = signers.map((signer) => {
            return Promise.all([
                SignerActions.fetchSignerSSN(casefileId, signer.id),
                SignerActions.fetchSignerRoles(casefileId, signer.id, true),
                SignerActions.fetchSignerEventLog(casefileId, signer.id),
            ]).then((response) => {
                let [ssn, roles, eventLog] = response;

                signer.ssn = ssn;
                signer.roles = roles;
                signer.eventLog = eventLog;
                signer.tempId = uniqid();

                return signer;
            });
        });

        return Promise.all(_signers)
            .then((newSigners) => {
                return newSigners.map((signer) => ({
                    id: signer.id,
                    name: signer.name,
                    email: signer.signingRequest.email,
                    onBehalfOf: signer.onBehalfOf,
                    roles: signer.roles,
                    ssn: signer.ssn,
                    ssnType: signer.ssnType,
                    vatin: signer.signingRequest.vatId ?? signer.vatin, // backwards compatibility
                    accessControl: signer.signingRequest.accessControl,
                    enableInsecureSigning:
                        signer.signingRequest.enableInsecureSigning,
                    insecureSigningMethods:
                        signer.signingRequest?.insecureSigningMethods ??
                        (signer.signingRequest.enableInsecureSigning
                            ? insecureMethods
                            : undefined),
                    reminderInterval: signer.signingRequest.reminderInterval,
                    eventLog: signer.eventLog,
                    type: 'signer',
                    status: signer.signingRequest.status,
                    storeAsContact: signer.storeAsContact,
                    signingRequestId: signer.signingRequest.id,
                    tempId: signer.tempId,
                    language: signer.language,
                }));
            })
            .catch((error) => {
                console.log('Error. Failed to fetch signers', error);
            });
    },

    _getDocuments(documents) {
        if (documents.length === 0) {
            return false;
        }

        const _documents = documents.map((doc) => ({
            id: doc.id,
            caseFileId: doc.caseFileId,
            name: doc.title,
            typeId: doc.documentTypeId,
            order: doc.documentOrder,
            status: doc.status,
            opts: doc.opts,
            format: doc.format,
            fileSize: doc.fileSize,
        }));

        return Promise.all(_documents);
    },

    /**
     * Retrieves the email templates from the first signer in the casefile
     * Currently, the UI only supports setting one email template for all of the signers.
     * i.e. All signers have the same email templates.
     *
     * @param {Array|undefined} signers
     * @return EmailTemplates object of email templates associated with signing requests.
     */
    _getEmailTemplates(signers) {
        // @todo: Make the email template extraction signer-specific when support for custom per-signer messages is added.

        // Return nothing if there aren't any signers.
        if (!signers || signers.length === 0) {
            return null;
        }

        const { signingRequest } = signers[0];

        // Support drafts that were saved before PN-360 fix.
        // Previously, drafts were being saved without any email template data
        // If the signing request doesn't have emailSubject or emailText, we return nothing
        if (!signingRequest.emailSubject && !signingRequest.emailText) {
            return null;
        }

        // @todo: convert this file to typescript and do a proper return type check
        // This response mimics the redux state for emailTemplates in
        // /Casefiles/redux/reducer/newCasefileReducer.ts
        return {
            [TemplateType.SIGNER]: {
                subject: signingRequest.emailSubject,
                message: signingRequest.emailText,
            },
            [TemplateType.COMPLETED]: {
                subject: signingRequest.completedEmailSubject,
                message: signingRequest.completedEmailText,
            },
            [TemplateType.REMINDER]: {
                subject: signingRequest.reminderEmailSubject,
                message: signingRequest.reminderEmailText,
            },
        };
    },

    _getRecipients(casefileId) {
        return SigningAPI.get(`/casefiles/${casefileId}/recipients`)
            .then((recipients) => {
                return recipients.map((recipient) => {
                    recipient.type = 'copyrecipient';

                    return recipient;
                });
            })
            .catch((error) => {
                console.log('Error. Failed to fetch copy recipients', error);
            });
    },

    _getFolder(casefileId) {
        return SigningAPI.get(`/casefiles/${casefileId}/folders`).then(
            ([folder]) => {
                return folder && folder.id;
            }
        );
    },

    CasefileException(data) {
        let { error, payload, sourceType } = data;

        const baseErrorDetails = {
            payload,
            status: error.status,
            statusText: error.statusText,
            headers: error.headers,
        };

        if (sourceType === 'SIGNER') {
            switch (error.status) {
                case 404:
                    return {
                        ...baseErrorDetails,
                        errorId: 'SIGNER_NOT_FOUND',
                        reason: `Signer ${payload.name} not found`,
                    };
                default:
                    return {
                        ...baseErrorDetails,
                        errorId: 'ERROR_ADD_SIGNER_FAILURE',
                        reason: `Could not add signer ${payload.name}`,
                    };
            }
        }

        if (sourceType === 'DOCUMENT') {
            switch (error.status) {
                case 413:
                    return {
                        ...baseErrorDetails,
                        errorId: 'ERROR_DOCUMENT_TOO_LARGE',
                        reason: `Document ${payload.title} is too large`,
                    };
                case 404:
                    return {
                        ...baseErrorDetails,
                        errorId: 'ERROR_DOCUMENT_NOT_FOUND',
                        reason: `Document not found`,
                    };
                default:
                    return {
                        ...baseErrorDetails,
                        errorId: 'ERROR_ADD_DOCUMENT_FAILURE',
                        reason: `Could not add document ${payload.title}`,
                    };
            }
        }

        if (sourceType === 'SIGNER_TYPE') {
            return {
                ...baseErrorDetails,
                errorId: 'LINK_SIGNER_TYPE',
                reason: 'Could not link signer type',
            };
        }

        if (sourceType === 'COPY_RECIPIENT') {
            return {
                ...baseErrorDetails,
                errorId: 'ADD_COPY_RECIPIENT',
                reason: 'Could not add copy recipient',
                message: error.data.message,
            };
        }
    },

    // *************************************************************************
    // USER INPUT ACTIONS
    // *************************************************************************
    setCasefileTypes(casefileTypes) {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCH_CASEFILE_TYPES_SUCCESS,
            casefileTypes: casefileTypes,
        });
    },

    setCaseTypeId(caseTypeId, resetDefaults = true) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_TYPE_CHANGED,
            caseTypeId: caseTypeId,
            resetDefaults: resetDefaults,
        });
    },

    setSendAtDate(date) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SEND_AT_DATE_CHANGED,
            date: date,
        });
    },

    setExpireAtDate(date) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.EXPIRE_AT_DATE_CHANGED,
            date: date,
        });
    },

    setRecipients(recipients) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.RECIPIENTS_CHANGED,
            recipients: recipients,
        });
    },

    updateCasefile(casefile) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_CHANGED,
            casefile: casefile,
        });
    },

    setSensitiveData(sensitiveData = false) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.SENSITIVE_DATA_CHANGED,
            sensitiveData: sensitiveData,
        });
    },

    /**
     * Update the folder of a casefile to be sent
     * If folder doesn't exist or the user doesn't have access to it, falls back to default folder.
     * @param selectedFolderId folder ID to set on casefile store.
     */
    async changeFolder(selectedFolderId: number) {
        try {
            // Verify that folder exists/can be fetched before switching to it.
            await SigningAPI.get(`/folders/${selectedFolderId}`);

            Dispatcher.handleViewAction({
                type: Constants.ActionTypes.FOLDER_CHANGED,
                selectedFolderId: selectedFolderId,
            });
        } catch (e) {
            debug.log(`Could not fetch folder ${selectedFolderId}`, e);
            this.changeFolderToDefault();
        }
    },

    /**
     * Changes folder to last used folder (stored in user settings)
     * If the user doesn't have a last used folder in settings, falls back to default folder.
     */
    async changeFolderToLastUsed() {
        const ReduxStore = store.getState();
        const lastUsedFolderId =
            ReduxStore.settings.data.user.lastUsedFolder?.folderId;

        // First time users won't have a `lastUsedFolderId` in their settings.
        if (lastUsedFolderId) {
            return await this.changeFolder(lastUsedFolderId);
        }

        this.changeFolderToDefault();
    },

    /**
     * Changes the current folder of a casefile to the default folder and overwrites the last used folder
     * with the default folder's ID.
     *
     * This is used as a fallback for cases where no `folderId` is set on a casefile or
     * the target folder of a casefile doesn't exist anymore.
     */
    async changeFolderToDefault() {
        const defaultFolder = await this.fetchDefaultFolder();

        // Store default folder in user settings.
        dispatch(
            updateUserSettings(UserSettingKeys.lastUsedFolder, {
                folderId: defaultFolder.id,
            })
        );

        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FOLDER_CHANGED,
            selectedFolderId: defaultFolder.id,
        });
    },

    setVisibilityMode(visibilityMode) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.VISIBILITY_MODE_CHANGED,
            visibilityMode: visibilityMode,
        });
    },

    clearStore() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_CASEFILE_STORE,
        });
    },

    clearFolders() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CLEAR_FOLDERS,
        });
    },

    clearError() {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CASEFILE_CLEAR_ERROR,
        });
    },

    async addDocumentToDraft(
        casefileId,
        doc,
        index,
        documentId,
        currentDocuments
    ) {
        try {
            const documentWithOptions = currentDocuments.find((d) => d.opts);
            // Sort the current documents by their order, excluding the current document
            const relevantDocuments = currentDocuments.filter(
                (d) => d._id !== doc._id
            );

            const penneoDocument = await SigningAPI.post(
                '/document-from-file',
                {
                    caseFileId: casefileId,
                    fileId: documentId,
                    title: doc.name,
                    documentTypeId: doc.documentTypeId,
                    documentOrder: relevantDocuments.length + index,
                    metaData: doc.metaData,
                    ...(documentWithOptions && {
                        opts: documentWithOptions.opts,
                    }),
                }
            );

            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.DRAFT_DOCUMENT_ADDED_SUCCESS,
                newDocument: {
                    id: penneoDocument.id,
                    caseFileId: penneoDocument.caseFileId,
                    name: penneoDocument.title,
                    typeId: penneoDocument.documentTypeId,
                    order: penneoDocument.documentOrder,
                    status: penneoDocument.status,
                    documentTypeId: penneoDocument.documentTypeId,
                    format: penneoDocument.format,
                    fileSize: doc.fileSize,
                    tempId: doc._id,
                },
                tempId: doc._id,
            });

            return { success: true, document: penneoDocument };
        } catch (error) {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.DRAFT_DOCUMENT_ADDED_FAILURE,
                document: doc,
            });

            return { success: false, error };
        }
    },

    /**
     * If the 'options' parameter is not used, the options will be extracted
     * from the available documents.
     * */
    updateConditionalSigningOptions(options?: {
        available: boolean;
        enabled: boolean;
        count: number;
        role: string;
    }) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.CONDITIONAL_SIGNING_OPTIONS_UPDATED,
            options,
        });
    },

    async populateStore(data) {
        if (data.templateId) {
            this.setCaseTypeId(data.templateId, false); // Force refresh of document types
        } else {
            // If draft doesn't have a `casefileTypeId`, set the caseTypeId to the default one. (First one of the list)

            // @note: This is possible with drafts created via API/Silverfin Integration when drafts are created without a casefile type ID
            this.setCaseTypeId(CasefileStore.getCasefileTypes()[0], false);
        }

        // new Case file object state
        let state = {
            id: data.id,
            title: data.name,
            language: data.language,
            metaData: data.metaData,
            signOnMeeting: data.signOnMeeting,
            sensitiveData: data.sensitiveData,
            reference: data.reference,
            createdAt: data.created,
            completedAt: data.completed,
        };

        // Remove undefined values
        Object.keys(state).forEach(
            (key) => state[key] === undefined && delete state[key]
        );

        // Update Case File Data
        if (!lodash.isEmpty(state)) {
            this.updateCasefile(state);
        }

        // Update folder
        if (data.folderId) {
            this.changeFolder(data.folderId);
        } else {
            this.changeFolderToLastUsed();
        }

        // Update visibility mode
        if (data.visibilityMode) {
            lodash.forEach(Constants.VISIBILITY_MODES, (value, key) => {
                if (value === data.visibilityMode) {
                    const modeCode = parseInt(key, 10);

                    this.setVisibilityMode(modeCode);
                }
            });
        }

        // Add casefile recipients
        if (data.copyRecipients) {
            let _recipients = data.copyRecipients.map((recipient) => {
                return lodash.omitBy(
                    {
                        // omitBy function removes undefined values
                        id: recipient.id,
                        name: recipient.name,
                        email: recipient.email,
                        type: recipient.type,
                    },
                    lodash.isUndefined
                );
            });

            this.setRecipients(_recipients);
        }

        // Update date of sending the casefile
        if (data.sendAt) {
            this.setSendAtDate(moment(data.sendAt));
        }

        // Update date of expiring the casefile
        if (data.expireAt) {
            this.setExpireAtDate(moment(data.expireAt));
        }
    },
};

export default CaseFileActions;

/**
 * Processes the given signer's data for sending it to tracking services
 *
 * Removes some basic sensitive data (name, email, etc) and obfuscate other
 * that we can't send in clear form but we still need to track (ssn, on behalf's name, etc)
 *
 * @param {object} signer
 * @return {object}
 */
function prepareSignerDataForTracking(signer) {
    const TO_REMOVE = [
        'name',
        'email',
        'type',
        'role',
        'id',
        'validation',
        'tempId',
    ];
    const TO_OBFUSCATE = ['onBehalfOf', 'ssn', 'vatin'];

    const roles = lodash.map(signer.role, 'id');
    const picked = lodash.pickBy(signer, (__, k) => !TO_REMOVE.includes(k));
    const obfuscated = lodash.mapValues(
        lodash.pick(picked, TO_OBFUSCATE),
        (value) => (value !== null ? !!value : value)
    );

    return {
        ...picked,
        ...obfuscated,
        roles,
    };
}
