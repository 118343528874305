import React from 'react';
import { images } from 'Constants';
import { i18n } from 'Language/i18n';
import { homepageUrl } from 'Constants';
import '../../signing-done-lead-gen.scss';
import '../../signing-footer-lead-gen.scss';
import { Languages } from 'Language/Constants';

type Props = {
    linkTracking(name: string, url: string): void;
    links: {
        name: string;
        url: string;
    }[];
    language: Languages;
    linkLocalization: (language?: Languages) => string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
};

export const LandingPageLayout = ({
    linkTracking,
    links,
    language,
    linkLocalization,
    style,
    children,
}: Props) => {
    return (
        <>
            <div className="signing-container-background">
                <div className="signing-content">
                    <img
                        className="penneo-logo"
                        src={`${images}/penneo-trust.svg`}
                        alt="Penneo Trust"
                    />
                    {children}
                </div>
            </div>
            <div className="footer" style={style}>
                <div className="footer-text">
                    <div className="text-block title">
                        {i18n`signingDone.footer.aboutPenneo`}
                    </div>
                    <div className="text-block links">
                        {links.map((link) => {
                            const href = `${homepageUrl}/${linkLocalization(
                                language
                            )}${link.url}`;

                            return (
                                <a
                                    key={link.name}
                                    href={href}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() =>
                                        linkTracking(link.name, href)
                                    }>
                                    {i18n(link.name)}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
