import React, { useState } from 'react';
import { images } from 'Constants';
import DocumentStatusListItem from './DocumentStatusListItem';
import { UploadStatus } from '../types';
import { UploadedDocument } from 'types/CaseFile';
import { UploadingDocument } from 'types/Document';
import { ProgressBar } from './ProgressBar';
import UITooltip from 'Common/components/Vega/Tooltip';

type Props = {
    file: UploadingDocument | UploadedDocument;
    cancelUpload?: () => void;
    edit?: () => void;
    preview?: () => void;
    remove?: () => void;
    documentType?: { name: string };
    uploadStatus?: UploadStatus;
    showProgressBar?: boolean;
    percentageUploaded?: number;
    isUploadingFile?: boolean;
    connectDragSource?: Function;
};

export const DocumentListItem = ({
    file,
    cancelUpload,
    edit,
    preview,
    remove,
    documentType,
    uploadStatus,
    showProgressBar,
    percentageUploaded,
    isUploadingFile,
    connectDragSource,
}: Props) => {
    const [isHovered, setIsHovered] = useState(false);

    const isError =
        typeof uploadStatus === 'object' && uploadStatus.status === 'error';

    const isDraft = !!file.caseFileId;

    const isPdf = isDraft
        ? (file as UploadedDocument).format === 'pdf'
        : ('fileContent' in file &&
              file.fileContent?.type === 'application/pdf') ||
          file.fileType === 'application/pdf';

    const fileIcon = `${isPdf ? 'pdf' : 'xbrl'}${isError ? '-error' : ''}.svg`;

    const fileSize = (file.fileSize / 1000000).toFixed(2);

    return (
        <div
            className={`box-border p-4 block justify-between items-center mt-2 border border-solid rounded border-gray-300 
             relative
            ${isError && 'border-red-700'} 
            ${!isError && ' hover:border-secondary-500'} 
            hover:shadow-md`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className="box-border w-full flex flex-col sm:flex-row justify-between gap-x-4">
                <div className="flex items-start">
                    <div className="flex items-center">
                        {isHovered &&
                            connectDragSource?.(
                                <img
                                    className="h-5 w-5 absolute left-0 cursor-move"
                                    src={`${images}/file-component/drag-and-drop.svg`}
                                />
                            )}
                        <div className="w-10 h-10 mr-2 flex items-center justify-center">
                            <img
                                className="h-10"
                                src={`${images}/file-component/${fileIcon}`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center h-full">
                        <div className="font-semibold text-sm text-gray-900 flex flex-col justify-center h-full">
                            <div className="flex min-w-0">
                                <div className="flex min-w-0 overflow-hidden gap-1 items-end">
                                    <span className="line-clamp-2 sm:line-clamp-1 break-all">
                                        {file.name}
                                    </span>
                                </div>
                                <UITooltip placeholder="top" offset={7}>
                                    {file.name}
                                </UITooltip>
                            </div>
                        </div>
                        <div className="text-gray-600">
                            <span>
                                {documentType?.name}
                                {fileSize &&
                                    `${
                                        documentType?.name ? ' •\u00A0' : ''
                                    }${fileSize}\u00A0MB`}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-end sm:items-start gap-2">
                    <DocumentStatusListItem
                        uploadStatus={uploadStatus}
                        {...{
                            file,
                            preview,
                            edit,
                            remove,
                            isUploadingFile,
                            showProgressBar,
                            cancelUpload,
                            percentageUploaded,
                        }}
                    />
                </div>
            </div>
            {showProgressBar && !isError && (
                <ProgressBar {...{ percentageUploaded, file, uploadStatus }} />
            )}
        </div>
    );
};
