import React from 'react';
import { i18n } from 'Language';
import { TabbedRoute } from 'types/TabbedRoute';
import { ArchiveRoutes, SignersArchiveRoutes } from '../../Constants';
import { Stats } from '../redux/reducer';
import Analytics from 'Common/Analytics';
import RouterV3Tabs from 'Common/components/RouterV3Tabs';

import './tabs.scss';
import { getUserAccess } from 'Casefiles/utils';

type Props = {
    stats: Stats;
    shared?: boolean;
    children?: JSX.Element;
};

const ArchiveTabs: React.FunctionComponent<Props> = ({
    stats,
    shared,
    children,
}) => {
    return <RouterV3Tabs routes={normalizeRoutes()}>{children}</RouterV3Tabs>;

    /**
     * Converts the structure of the routes defined in the contants so that it
     * matches the expected structure of `TabbedRoute`s. This way the routes
     * can be consumed by <RouterV3Tabs />
     */
    function normalizeRoutes(): TabbedRoute[] {
        const { isSignersArchive } = getUserAccess();

        const route = isSignersArchive ? SignersArchiveRoutes : ArchiveRoutes;

        return route.map(
            (archiveRoute): TabbedRoute => {
                const { id, route } = archiveRoute;
                const title = buildTabbedRouteTitle(archiveRoute);

                /**
                 * If we are on a Shared folder, we extend the route to force it to be active
                 */
                const newRoute =
                    shared && id === 'shared'
                        ? {
                              ...route,
                              ...{
                                  params: {
                                      ...route.params,
                                      forceActive: true,
                                  },
                              },
                          }
                        : route;

                return {
                    ...newRoute,
                    tab: {
                        id,
                        title,
                        noPadding: true,
                        onClick: () => {
                            Analytics.track('archive - change view', {
                                id,
                                isSignersArchive,
                            });
                        },
                    },
                };
            }
        );
    }

    /**
     * Adds the item count to the tab title
     */
    function buildTabbedRouteTitle(tab): JSX.Element {
        const { id, label } = tab;

        return (
            <>
                {i18n(label)}
                {stats.items[id] && (
                    <span className="penneo-menu-tab-count">
                        <span>
                            {stats.isFetching ? '...' : stats.items[id].count}
                        </span>
                    </span>
                )}
            </>
        );
    }
};

export default ArchiveTabs;
