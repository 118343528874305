import React from 'react';
import { i18n } from 'Language';
import { CaseFileItem, Type } from '../';
import {
    ACCESS_FULL,
    ARCHIVE_ROUTE_NAMES,
    PERSONAL_ARCHIVE_ROUTE_NAMES,
} from 'Constants';
import UserStore from 'Auth/stores/UserStore';
import Tooltip from 'Common/components/Tooltip';
import Analytics from 'Common/Analytics';
import TooltipFolderOwner from '../TooltipFolderOwner';
import Button from 'Common/components/Button';
import { ShareIcon } from '@heroicons/react/20/solid';
import { FolderMinusIcon, StarIcon } from '@heroicons/react/24/outline';
import { getUserAccess } from 'Casefiles/utils';
import { useAppSelector } from 'Store';

type OnActionCaseFileItem = (item: CaseFileItem) => void;

export type Props = {
    item: CaseFileItem;
    onPreferred: OnActionCaseFileItem;
    onUnshare: OnActionCaseFileItem;
    router: any;
};

enum FOLDER_TYPE {
    DEFAULT = 'default',
    REGULAR = 'regular',
    SHARED = 'shared',
}

const getFolderType = (item: CaseFileItem): FOLDER_TYPE => {
    const loggedInUser = UserStore.getCurrentUser();

    if (loggedInUser.id !== item.userId) {
        return FOLDER_TYPE.SHARED;
    }

    return item.folderType === Type.Unshareable
        ? FOLDER_TYPE.DEFAULT
        : FOLDER_TYPE.REGULAR;
};

export const CasefileFolderActions: React.FunctionComponent<Props> = ({
    item,
    onPreferred,
    onUnshare,
    router,
}) => {
    const folderType = getFolderType(item);

    const folderOwnerId = useAppSelector(
        (state) => state.archive.folderOwnerId
    );

    const loggedInUser = UserStore.getCurrentUser();

    const { isSignersArchive } = getUserAccess();

    const onShareClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        Analytics.track('archive - open share folder', {
            id: item.id,
            isSignersArchive,
        });
        router.push({
            name: isSignersArchive
                ? PERSONAL_ARCHIVE_ROUTE_NAMES.folderShareRoute
                : ARCHIVE_ROUTE_NAMES.folderShareRoute,
            params: {
                folderId: item.id,
            },
        });
    };

    return (
        <span className="item-actions flex items-center">
            {/* Owner */}
            <TooltipFolderOwner
                folder={item}
                tooltipClassName="item-action-tooltip no-animations"
            />
            {item.accessLevel === ACCESS_FULL && (
                <div
                    onClick={(event: React.MouseEvent) =>
                        event.stopPropagation()
                    }>
                    <Tooltip
                        className="item-action-tooltip share-folder-tooltip no-animations inline-flex justify-center items-center"
                        showArrow={true}
                        text={
                            <span>
                                {item.parentShared === true
                                    ? i18n`shareFolder.cannotShareTooltip`
                                    : i18n`Share Folder`}
                            </span>
                        }>
                        <Button
                            variant="text"
                            theme="gray"
                            className="h-8 w-8 p-1.5"
                            disabled={item.parentShared === true}
                            onClick={onShareClick}>
                            <ShareIcon className="h-5"></ShareIcon>
                        </Button>
                    </Tooltip>
                </div>
            )}

            {folderType === FOLDER_TYPE.DEFAULT && (
                <Tooltip
                    className="item-action-tooltip no-animations inline-flex justify-center items-center"
                    showArrow={true}
                    text={<span>{i18n`Default Folder`}</span>}
                    data-testid="default-folder-indicator">
                    <Button
                        variant="text"
                        theme="gray"
                        className="h-8 w-8 p-1.5">
                        <StarIcon className="h-5 text-secondary-500 fill-secondary-500"></StarIcon>
                    </Button>
                </Tooltip>
            )}
            {folderType === FOLDER_TYPE.REGULAR && (
                <Tooltip
                    className="item-action-tooltip no-animations inline-flex justify-center items-center"
                    showArrow={true}
                    text={<span>{i18n`Set as default`}</span>}
                    data-testid="default-folder-button">
                    <Button
                        variant="text"
                        theme="gray"
                        className="h-8 w-8 p-1.5"
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            onPreferred(item);
                        }}>
                        <StarIcon className="h-5"></StarIcon>
                    </Button>
                </Tooltip>
            )}

            {loggedInUser.id !== item.userId &&
                loggedInUser.id !== folderOwnerId &&
                item.shared && (
                    <Tooltip
                        className="item-action-tooltip no-animations inline-flex justify-center items-center"
                        showArrow={true}
                        text={i18n('Stop sharing with me')}
                        data-testid="unshare-button">
                        <Button
                            variant="text"
                            theme="gray"
                            className="h-8 w-8 p-1.5"
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                onUnshare(item);
                            }}>
                            <FolderMinusIcon className="h-5"></FolderMinusIcon>
                        </Button>
                    </Tooltip>
                )}
        </span>
    );
};
