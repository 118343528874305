import LoadingData from 'Common/components/Loaders/LoadingData';
import { images } from 'Constants';
import { i18n } from 'Language';
import React, { useEffect, useCallback } from 'react';
import { CustomerBranding, signingRequestStatus } from 'types/SigningProcess';
import { checkColorSchemeValid, trackSigning } from '../utils';
import { Languages } from 'Language/Constants';
import UIButton from 'Common/components/Vega/Button';
import {
    ArrowTopRightOnSquareIcon,
    ArrowRightIcon,
} from '@heroicons/react/20/solid';
import '../signing-done-lead-gen.scss';
import '../signing-footer-lead-gen.scss';
import { LandingPageLayout } from './Common/LandingPageLayout';
import { InfoSection } from './Common/InfoSection';

const URL_PENNEO_BASE = 'https://penneo.com';
const EVENT_PAGE_LOADED = 'Signing Completed Promote Penneo - Page Loaded';
const EVENT_FREE_TRIAL_CLICKED = 'Get a Free Trial - Button Clicked';
const EVENT_LEARN_MORE_CLICKED = 'Learn More About Penneo - Link Clicked';
const EVENT_GO_TO_ARCHIVE_CLICKED = 'Go to Archive - Button Clicked';

type Props = {
    isSigningDoneLeadGen: boolean;
    requestStatus: signingRequestStatus;
    shouldRedirect: boolean;
    linkTracking(name: string, url: string): void;
    links: {
        name: string;
        url: string;
    }[];
    language: Languages;
    branding: CustomerBranding;
    linkLocalization: (language?: Languages) => string;
    caseFileTypeName?: string;
};

export const SigningDoneComponents = ({
    isSigningDoneLeadGen,
    requestStatus,
    shouldRedirect,
    linkTracking,
    links,
    language,
    branding,
    linkLocalization,
    caseFileTypeName,
}: Props) => {
    useEffect(() => {
        if (
            requestStatus === signingRequestStatus.signed &&
            isSigningDoneLeadGen
        ) {
            trackSigning(EVENT_PAGE_LOADED);
        }
    }, [isSigningDoneLeadGen, requestStatus]);

    const visitPenneo = `${URL_PENNEO_BASE}/${linkLocalization(
        language
    )}digital-signing-data-collection/?utm_source=after-signing-page`;
    const tryPenneo = `${URL_PENNEO_BASE}/${linkLocalization(
        language
    )}free-trial/?utm_source=after-signing-page`;

    const handleFreeTrialClick = () => {
        window.open(tryPenneo, '_blank', 'noopener noreferrer');
        trackSigning(EVENT_FREE_TRIAL_CLICKED, {
            caseFileSigningFlow: caseFileTypeName,
        });
    };

    const handleGoToArchiveClick = () => {
        window.open('/casefiles/manage', '_blank', 'noopener noreferrer');
        trackSigning(EVENT_GO_TO_ARCHIVE_CLICKED, {
            caseFileSigningFlow: caseFileTypeName,
        });
    };

    const renderContent = useCallback(() => {
        if (shouldRedirect) {
            return <LoadingData />;
        }

        switch (requestStatus) {
            case signingRequestStatus.signed:
                return (
                    <>
                        <InfoSection
                            title={i18n`signingDone.signed.title`}
                            description={i18n`signingDone.signed.description`}
                        />
                        <div className="archive-container">
                            <div className="archive-content">
                                <div className="archive-info">
                                    <img
                                        src={`${images}/folder-confirmed.svg`}
                                        alt="Folder Confirmed"
                                    />
                                    <div className="archive-text">
                                        {i18n`signingDone.signed.archiveInfo`}
                                    </div>
                                </div>
                                <UIButton
                                    onClick={handleGoToArchiveClick}
                                    priority="tertiary"
                                    type="success"
                                    size="md"
                                    theme="dark">
                                    {i18n`signingDone.signed.archiveButton`}
                                    <ArrowRightIcon className="h-6"></ArrowRightIcon>
                                </UIButton>
                            </div>
                        </div>
                        {isSigningDoneLeadGen && (
                            <>
                                <div className="visit-penneo">
                                    <div className="visit-text">
                                        {i18n`signingDone.signed.leadGenInterested`}
                                    </div>
                                    <a
                                        href={visitPenneo}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() =>
                                            trackSigning(
                                                EVENT_LEARN_MORE_CLICKED,
                                                {
                                                    caseFileSigningFlow: caseFileTypeName,
                                                }
                                            )
                                        }>
                                        {i18n`signingDone.signed.leadGenLearnMore`}
                                        <ArrowTopRightOnSquareIcon className="h-6 ml-2"></ArrowTopRightOnSquareIcon>
                                    </a>
                                </div>
                                <div className="section-title about-penneo">{i18n`signingDone.signed.leadGenAboutTitle`}</div>
                                <div className="section-description">
                                    {i18n`signingDone.signed.leadGenDescription`}
                                </div>
                                <div className="info-text">
                                    {i18n`signingDone.signed.leadGenSecurityInfo`}
                                </div>
                                <div className="try-penneo">
                                    <UIButton
                                        onClick={handleFreeTrialClick}
                                        priority="primary"
                                        type="accent"
                                        size="md">
                                        {i18n`signingDone.signed.leadGenTryButton`}
                                    </UIButton>
                                </div>
                                <div className="dashboard-image">
                                    <img
                                        src={`${images}/penneo-sign-dashboard.svg`}
                                        alt="Penneo Sign Dashboard"
                                    />
                                </div>
                            </>
                        )}
                    </>
                );
            case signingRequestStatus.rejected:
                return (
                    <InfoSection
                        title={i18n`signingDone.rejected.title`}
                        info={i18n`signingDone.rejected.info`}
                    />
                );
            case signingRequestStatus.expired:
                return (
                    <InfoSection
                        title={i18n`signingDone.expired.title`}
                        description={i18n`signingDone.expired.description`}
                        info={i18n`signingDone.contactInfo`}
                    />
                );
            case signingRequestStatus.deleted:
            case signingRequestStatus.pending:
                return (
                    <InfoSection
                        title={i18n`signingDone.title`}
                        description={i18n`signingDone.deleted.description`}
                        info={i18n`signingDone.contactInfo`}
                    />
                );
            default:
                return null;
        }
    }, [
        shouldRedirect,
        requestStatus,
        isSigningDoneLeadGen,
        visitPenneo,
        caseFileTypeName,
        handleGoToArchiveClick,
        handleFreeTrialClick,
    ]);

    return (
        <>
            <LandingPageLayout
                linkTracking={linkTracking}
                links={links}
                language={language}
                linkLocalization={linkLocalization}
                style={{
                    ...(branding &&
                        checkColorSchemeValid(branding) && {
                            backgroundColor: branding?.backgroundColor,
                        }),
                }}>
                {renderContent()}
            </LandingPageLayout>
        </>
    );
};
