import PropTypes from 'prop-types';
import React from 'react';
import CasefileAccessDenied from '../errors/CasefileAccessDenied';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import { Routes } from 'Constants';
import { getUserAccess } from 'Casefiles/utils';

const CasefilesWrapper = (props) => {
    const { router } = props;

    const { isSignersArchive: shouldRedirect } = getUserAccess();

    useCustomerRedirect({
        shouldRedirect,
        shouldRedirectNonCustomer: true,
        redirectRoute: Routes.defaultSignersArchiveRoute,
        router,
    });

    return <Casefiles {...props} />;
};

class Casefiles extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        user: PropTypes.object,
        router: PropTypes.object,
    };

    render() {
        const { user, language } = this.props;
        const noRights = !user.rights;
        const noCustomers = !user.customerIds || user.customerIds.length === 0;

        if (noCustomers || noRights) {
            return <CasefileAccessDenied user={user} />;
        }

        return (
            <div className="penneo-casefiles">
                {React.cloneElement(this.props.children, {
                    user,
                    language,
                })}
            </div>
        );
    }
}

export default CasefilesWrapper;
