import React from 'react';
import bytes from 'bytes';
import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import lodash from 'lodash';
import { notify } from 'react-notify-toast';
import { i18n } from 'Language';

import { SignerRole } from 'types/SignerRole';
import { DocumentType } from 'types/Document';

// Actions for store-to-store communication
import SignerActions from '../actions/SignerActionCreators';
import DocumentActions from '../actions/DocumentActionCreators';
import CasefileActions from '../actions/CasefilesV2ActionCreators';

// Validation
import {
    validateDocumentSetup,
    validateDocuments,
    validateDocumentTypes,
} from 'Casefiles/utils/casefileValidation';
import { UploadStatus } from 'Casefiles/components/casefiles2/types';

let _documents: any[] = [];
let _availableDocumentTypes: DocumentType[] = [];
let _fetchedFiles: any[] = [];
let _uploadStatuses: { [docId: string]: UploadStatus } = {};
let _uploadTimeouts: { [docId: string]: ReturnType<typeof setTimeout> } = {};
let _uploadPercentages: { [docId: string]: number } = {};

function updateValidation(
    documents = _documents,
    documentTypes = _availableDocumentTypes
) {
    return {
        setup: validateDocumentSetup(documents),
        documents: validateDocuments(documents),
        documentTypes: validateDocumentTypes(documentTypes, documents),
    };
}

let _validation = updateValidation();

/**
 * Updates documents and document types, if documents argument is omitted, will refresh
 * document types for currently added documents.
 * @param  {Array} documents [optional] list of documents, defaults to current documents
 *
 * @return {void}
 */
function updateDocuments(documents = _documents, resetDefaults = true) {
    _documents = lodash.orderBy(documents, 'order');

    // Set default types to documents that don't have one already.
    if (_availableDocumentTypes.length === 0) {
        return;
    }

    _documents.forEach(function (doc) {
        matchDocumentTypes(doc, resetDefaults);
    });
}

function updateDocumentId(docId, newDocument) {
    const index = _documents.findIndex((_doc) => _doc._id === docId);

    _documents[index] = { ...newDocument };
}

/**
 * Matches and assigns document type to each document of _document list.
 * @param  {Object} doc Document from _document array.
 *
 * @return {void} (Mutates original object)
 */
function matchDocumentTypes(doc, resetDefaults = true) {
    let defaultRole = _availableDocumentTypes[0].id;

    // If the document doesn't have a document type, add the first available role.
    if (!doc.documentTypeId) {
        doc.documentTypeId = defaultRole;

        return;
    }

    let match;

    // If the document is an integer ID, match by ID, if it's a string, match by name.
    if (isNaN(doc.documentTypeId)) {
        match = _getDocumentTypeByName(doc.documentTypeId);
    } else {
        // If the document ID is an integer, verify that it matches an available role.
        match = _getDocumentTypeById(doc.documentTypeId);
    }

    if (match) {
        doc.documentTypeId = match.id;

        return;
    }

    // If no match is found, set default role.
    if (resetDefaults) {
        doc.documentTypeId = defaultRole;
    }
}

function updateSignerRoles() {
    // Extract signer roles from  selected document types
    let documentTypes = DocumentStore.getDocumentTypes();
    let signerRoles = extractSignerRoles(documentTypes);

    // Trigger signer roles update.
    SignerActions.updateSignerRoles(signerRoles);
}

function extractSignerRoles(documentTypes) {
    let roles: SignerRole[] = [];

    documentTypes.forEach((id) => {
        let documentType = _getDocumentTypeById(id);

        if (!documentType) {
            return;
        }

        documentType.signerTypes.forEach((role) => roles.push(role));
    });

    // Clean duplicates and reorder by role name.
    roles = lodash.uniqBy(roles, 'id');
    roles = lodash.sortBy(roles, 'role');

    return roles;
}

function _getDocumentTypeById(documentTypeId): DocumentType {
    return _availableDocumentTypes.filter(
        (type) => type.id === Number(documentTypeId)
    )[0];
}

function _getDocumentTypeByName(documentType): DocumentType {
    return _availableDocumentTypes.filter(
        (type) => type.name === documentType
    )[0];
}

/**
 * Sorts documents. Primarily by if the document is signable or an attachment
 * and secondarily by alphatic order.
 */
function updateAvailableDocumentTypes(documentTypes) {
    const docTypesWithoutSigners = documentTypes.filter(
        (documentType) => !documentType.signerTypes.length
    );

    const docTypesWithSigners = documentTypes.filter(
        (documentType) => documentType.signerTypes.length
    );

    const sortedDocTypesWithoutSigners = lodash.sortBy(
        docTypesWithoutSigners,
        'name'
    );

    const sortedDdocTypesWithSigners = lodash.sortBy(
        docTypesWithSigners,
        'name'
    );

    _availableDocumentTypes = [
        ...sortedDdocTypesWithSigners,
        ...sortedDocTypesWithoutSigners,
    ];
}

function updateDocumentTypeOptions(documentTypeId, value) {
    let documentType = _getDocumentTypeById(documentTypeId);

    documentType.opts[0].value = value;
}

function convertToUploadedDocument(tempId, newId) {
    const index = _documents.findIndex((_doc) => _doc._id === tempId);
    let doc = _documents[index];

    // Add id to uploaded document
    doc.id = newId;
    // Add content of the uploaded file
    doc.tempId = tempId;
    // Remove temporary id
    delete doc._id;
    // Remove file from the document object
    delete doc.file;

    _documents[index] = assign({}, doc);
}

// If a file exists it returns its base64, otherwise it returns false.
function _getFiles(docs) {
    if (docs.valid.length > 0) {
        // sending only the new documents
        DocumentActions.setDocuments(docs.valid, false);
    }

    if (docs.invalid.length > 0) {
        showNotAddedDocuments(docs.invalid);
    }
}

function showNotAddedDocuments(docs) {
    notify.show(
        <span>
            {i18n`The following documents could not be imported`}
            <div style={{ textAlign: 'left', fontSize: '0.9em' }}>
                {docs.map((d, i) => (
                    <span
                        key={i}
                        style={{ display: 'block', paddingTop: '0.5em' }}>
                        <i className="far fa-file-alt" />
                        &nbsp;
                        {extractPath(d.localPath)}
                    </span>
                ))}
            </div>
        </span>,
        'error'
    );
}

function extractPath(path) {
    if (!path) {
        return i18n('Unknown file');
    }

    if (path.indexOf('/') >= 0) {
        let array = path.split('/');

        return array[array.length - 1];
    }

    if (path.indexOf('\\') >= 0) {
        let array = path.split('\\');

        return array[array.length - 1];
    }

    return path;
}

function assignOrder(documents) {
    // sorts newly added documents
    let sortedDocuments = lodash.orderBy(documents, 'order');
    // adds the new documents to documents that are already in the store
    let allDocuments = DocumentStore.getDocuments().concat(sortedDocuments);

    // reassigns a new order
    allDocuments.forEach((doc, index) => (doc.order = index));

    return allDocuments;
}

function clearStore() {
    _documents = [];
}

function updateDownloadedFiles(blob, name) {
    _fetchedFiles.push({
        content: blob,
        name: name,
    });
}

function updateFetchedDocuments(documents) {
    _documents = documents;
}

function setBoardSignCountInDocuments(shouldBeEnabled: boolean, count: number) {
    return _documents.forEach((doc) => {
        if (!doc.opts) {
            return;
        }

        // Set the count on the docs
        if (doc.opts.hasOwnProperty('boardSignCount')) {
            doc.opts.boardSignCount = count;
            doc.opts.conditionalSigningEnabled = shouldBeEnabled;
        }
    });
}

function setUploadPercentage(docId: string, percentage: number) {
    _uploadPercentages[docId] = percentage;
}

function setUploadStatus(docId: string, status: UploadStatus) {
    _uploadStatuses[docId] = status;

    if (status === 'uploading') {
        _setDelayedTimeout(docId);
    } else {
        _clearDelayedTimeout(docId);
    }
}

function _setDelayedTimeout(docId: string) {
    _clearDelayedTimeout(docId);

    _uploadTimeouts[docId] = setTimeout(() => {
        if (_uploadStatuses[docId] === 'uploading') {
            setUploadStatus(docId, 'delayed');
            DocumentStore.emitChange(Constants.ActionTypes.SET_UPLOAD_STATUS);
        }
    }, 6000);
}

function _clearDelayedTimeout(docId: string) {
    if (_uploadTimeouts[docId]) {
        clearTimeout(_uploadTimeouts[docId]);
        delete _uploadTimeouts[docId];
    }
}

/* Flux Document Store Creation */
const DocumentStore = assign({}, BaseStore, {
    getDocuments() {
        return _documents;
    },

    /**
     * Returns the total size (in bytes) of all the documents in the store
     *
     * @return {int}
     */
    getDocumentsTotalSize() {
        return _documents.reduce((total, { fileContent }) => {
            // If document has no fileContent which has the processed document size, return the current total value
            if (!fileContent) {
                return total;
            }

            return total + fileContent.size;
        }, 0);
    },

    /**
     * Checks if the total size of the documents exceeds the size threshold for
     * copy emails
     *
     * @return {boolean}
     */
    isDocsSizeOverCopyEmailThreshold() {
        const docsSizeInBytes = this.getDocumentsTotalSize();
        const thresholdInBytes = bytes(Constants.MAX_DOCS_SIZE_FOR_COPY_EMAIL);

        return docsSizeInBytes > thresholdInBytes;
    },

    getAvailableDocumentTypes(): DocumentType[] {
        return _availableDocumentTypes;
    },

    // Returns an array of IDs of the selected document types.
    getDocumentTypes() {
        return _documents.map((doc) => doc.documentTypeId);
    },

    getDocumentTypeById(id) {
        return _availableDocumentTypes.filter((type) => type.id === id)[0];
    },

    // Gets the first document type with options
    // @note: This still returns the first element to preserve backwards compatibiltiy with the desktop app.
    getDocumentOptions() {
        return this.getDocumentTypesWithOptions()[0]; // Get first document
    },

    getDocumentTypesWithOptions() {
        return this.getAvailableDocumentTypes() // get document type IDs
            .map((docType) => _getDocumentTypeById(docType.id)) // make array of document types.
            .filter((type) => type && type.opts); // filter types that have opts.
    },

    getDocumentTypeWithBoardSignOptions() {
        const documentTypesWithOptions = this.getDocumentTypesWithOptions();

        return documentTypesWithOptions.find(({ opts }) => {
            return opts.find((opt) => opt.name === 'boardSignCount');
        });
    },

    getBoardSignEnabled() {
        const doc = _documents.find(
            (doc) => doc?.opts && doc?.opts?.conditionalSigningEnabled
        );

        return !!doc;
    },

    getBoardSignCountFromDocuments() {
        const doc = _documents.find(
            (doc) => doc?.opts && doc?.opts?.boardSignCount
        );

        if (doc) {
            return doc?.opts?.boardSignCount ?? 0;
        }

        return 0;
    },

    getRelevantSignerTypeRoleFromDocument() {
        const doc = this.getDocumentTypeWithBoardSignOptions();

        const signerType = doc.signerTypes.filter(
            (type) =>
                type.conditions &&
                type.role &&
                type.conditions.includes(type.role)
        );

        if (signerType.length) {
            return signerType[0].role;
        }

        return '';
    },

    getDocumentType(documentTypeId): DocumentType {
        return _availableDocumentTypes.filter(
            (doc) => doc.id === Number(documentTypeId)
        )[0];
    },

    getValidation() {
        return _validation;
    },

    getDownloadedDocuments() {
        return _fetchedFiles;
    },

    getUploadStatuses() {
        return _uploadStatuses;
    },

    getUploadPercentages() {
        return _uploadPercentages;
    },

    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.DOCUMENTS_CHANGED:
                updateDocuments(
                    assignOrder(action.documents),
                    action.resetDefaults
                );
                updateSignerRoles();
                DocumentStore.emitChange(
                    Constants.ActionTypes.DOCUMENTS_CHANGED
                );
                break;
            case Constants.ActionTypes.DOCUMENT_OPTIONS_CHANGED:
                updateDocumentTypeOptions(action.documentTypeId, action.value);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.AVAILABLE_DOCUMENT_TYPES_UPDATED:
                updateAvailableDocumentTypes(action.documentTypes);
                updateDocuments(_documents, action.resetDefaults);
                CasefileActions.updateConditionalSigningOptions();
                updateSignerRoles();
                break;
            case Constants.ActionTypes.DOCUMENT_ADDED:
                action.doc.order = _documents.length;
                _documents.push(action.doc);
                updateDocuments(_documents);
                updateSignerRoles();
                DocumentStore.emitChange(Constants.ActionTypes.DOCUMENT_ADDED);
                break;
            case Constants.ActionTypes.DOCUMENT_REORDER:
                {
                    let { index, newIndex } = action;

                    // @see https://stackoverflow.com/a/5306832/781779
                    // @see array-move implementation
                    if (newIndex >= _documents.length) {
                        let k = newIndex - _documents.length;

                        while (k-- + 1) {
                            _documents.push(null);
                        }
                    }

                    _documents.splice(
                        newIndex,
                        0,
                        _documents.splice(index, 1)[0]
                    );

                    // Reset order based on new index
                    _documents.forEach((doc, i) => (doc.order = i));
                    updateDocuments(_documents);
                    updateSignerRoles();
                }
                break;
            case Constants.ActionTypes.DOCUMENT_EDITED:
                _documents[action.index] = assign(
                    {},
                    _documents[action.index],
                    action.payload
                );
                updateDocuments(_documents);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.DOCUMENT_UPLOADED:
                convertToUploadedDocument(action.tempId, action.newId);
                break;
            case Constants.ActionTypes.DOCUMENT_ROLE_UPDATED:
                _documents[action.index] = assign(
                    {},
                    _documents[action.index],
                    action.payload
                );
                updateDocuments(_documents);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.DOCUMENT_REMOVED:
                const documentIndex = _documents.findIndex(
                    (doc) => doc._id === action.docId || doc.id === action.docId
                );

                _documents.splice(documentIndex, 1);
                updateDocuments(_documents);
                updateSignerRoles();
                break;
            // use for desktop only, different ui and behaviour
            case Constants.ActionTypes.DOCUMENT_REMOVED_BY_INDEX:
                _documents.splice(action.index, 1);
                updateDocuments(_documents);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.NOT_UPLOADED_DOCUMENTS_REMOVED:
                _documents = _documents.filter((doc) => 'id' in doc);
                updateDocuments(_documents);
                break;
            case Constants.ActionTypes.LOCAL_FILES_LOADED:
                _getFiles(action.files);
                break;
            case Constants.ActionTypes.DRAFT_DOCUMENT_ADDED_SUCCESS:
                updateDocumentId(action.tempId, action.newDocument);
                updateDocuments(_documents);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.PDF_BLOB_LOADED:
                updateDownloadedFiles(action.blob, action.name);
                break;
            case Constants.ActionTypes.FETCH_DOCUMENTS_SUCCESS:
                updateFetchedDocuments(action.documents);
                updateSignerRoles();
                break;
            case Constants.ActionTypes.CLEAR_DOCUMENT_STORE:
                clearStore();
                _uploadStatuses = {};
                _uploadPercentages = {};
                break;
            case Constants.ActionTypes.SET_CONDITIONAL_SIGNING_COUNT:
                setBoardSignCountInDocuments(
                    action.shouldBeEnabled,
                    action.count
                );
                break;
            case Constants.ActionTypes.SET_UPLOAD_STATUS:
                setUploadStatus(action.docId, action.status);
                DocumentStore.emitChange(
                    Constants.ActionTypes.SET_UPLOAD_STATUS
                );
                break;
            case Constants.ActionTypes.DOCUMENT_UPLOAD_PROCESS:
                setUploadPercentage(action.docId, action.percent);
                DocumentStore.emitChange(
                    Constants.ActionTypes.DOCUMENT_UPLOAD_PROCESS
                );
                break;
            case Constants.ActionTypes.CLEAR_UPLOAD_STATUSES:
                _uploadStatuses = {};
                DocumentStore.emitChange(
                    Constants.ActionTypes.CLEAR_UPLOAD_STATUSES
                );
                break;
            case Constants.ActionTypes.REMOVE_UPLOAD_STATUS:
                delete _uploadStatuses[action.docId];
                DocumentStore.emitChange(
                    Constants.ActionTypes.REMOVE_UPLOAD_STATUS
                );
                break;
            default:
                return true;
        }
        _validation = updateValidation();

        // If action was responded to, emit change event
        DocumentStore.emitChange();

        return true;
    }),
});

export default DocumentStore;
