import { getUserAccess } from 'Casefiles/utils';
import { Routes } from 'Constants';
import { useEffect } from 'react';

type UseCustomerRedirect = {
    shouldRedirect?: boolean;
    redirectRoute?: string;
    shouldRedirectNonCustomer?: boolean;
    shouldRedirectCustomer?: boolean;
    router: any;
};

const useCustomerRedirect = ({
    shouldRedirect = true,
    redirectRoute = Routes.defaultV2RouteName,
    shouldRedirectNonCustomer = true,
    router,
}: UseCustomerRedirect) => {
    useEffect(() => {
        if (!shouldRedirect) return;

        const { isCustomer } = getUserAccess();

        if (isCustomer) {
            router.replace(redirectRoute);
        } else {
            if (redirectRoute && shouldRedirectNonCustomer) {
                router.replace(redirectRoute);
            }
        }
    }, [redirectRoute, shouldRedirectNonCustomer]);
};

export default useCustomerRedirect;
