import React, { useEffect, useState } from 'react';
import Constants, { Routes } from 'Constants';
import { i18n } from 'Language';
import Loading from 'Common/components/Loaders/LoadingData';
import { Link } from 'react-router';
import moment from 'moment';
import { Helmet } from 'react-helmet';

// Redux
import { connect } from 'react-redux';
import {
    fetchCasefile,
    fetchCasefileAttachments,
    fetchCasefileFolder,
    resetCasefileFolder,
} from 'Casefiles/CasefileDetails/redux/actions';
import {
    CasefileState,
    SignerState,
    EventLogState,
    CasefileAttachmentsState,
    FolderState,
} from 'Casefiles/CasefileDetails/redux/types';

// Components
import CasefileDocuments from 'Casefiles/CasefileDetails/CasefileDocuments';

import {
    archiveInitialBreadcrumb,
    BreadcrumbsBase,
    getFolderPredecessors,
    updateBreadcrumbsAction,
    addBreadcrumbsAction,
    deleteBreadcrumbs,
} from 'Casefiles/Archive/redux/reducer';

import 'Casefiles/CasefileDetails/casefile-details.scss';
import { ReduxState } from 'Store';
import { images } from 'Constants';
import StatusBanner from 'Casefiles/CasefileDetails/StatusBanner';
import { getCasefileStatus } from 'Casefiles/CasefileDetails/statusCodes';
import Button from 'Common/components/Button';
import { ReactRouter } from 'types/Router';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import Analytics from 'Common/Analytics';

type Props = {
    casefileId: number;
    params: {
        casefileId: string;
        documentId?: string;
        signerId?: string;
    };
    router: ReactRouter;
    modal: JSX.Element;
    caseFile: CasefileState;
    eventLog: EventLogState;
    signers: SignerState;
    folder: FolderState;
    attachments: CasefileAttachmentsState;
    dispatch: any;
    breadcrumbs: BreadcrumbsBase[];
};

const SignersCasefileDetails = (props: Props) => {
    const {
        dispatch,
        breadcrumbs,
        caseFile,
        casefileId,
        folder,
        eventLog,
        attachments,
        router,
        modal,
    } = props;

    const [hasBreadcrumbs, setHasBreadcrumbs] = useState(false);
    const [loadedBreadcrumbs, setLoadedBreadcrumbs] = useState(false);

    const formatDate = (date: any) => {
        return moment.unix(date).format('DD/MM/YYYY - HH:mm');
    };

    const trackDownload = () => {
        Analytics.track('archive - download case file', {
            isSignersArchive: true,
        });
    };

    const loadData = async () => {
        try {
            const caseFileResult = await dispatch(fetchCasefile(casefileId));

            await dispatch(fetchCasefileFolder(casefileId));

            if (caseFileResult?.data) {
                await dispatch(fetchCasefileAttachments(caseFileResult.data));
            }
        } catch (error) {
            console.error('Error in loadData:', error);
        }
    };

    const renderError = (error: any) => {
        if (error.status === 403) {
            return (
                <div className="casefile-details ui-v2">
                    <div className="casefile-error text-center">
                        <h2 className="code">[Code: 403]</h2>
                        <img
                            src={`${images}/graphics/security.png`}
                            height="300"
                            alt=""
                        />
                        <h1>{i18n`You don't have access to this case`}</h1>

                        <Button theme="blue" onClick={() => router.goBack()}>
                            {i18n`Back to archive`}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="casefile-details ui-v2">
                <div className="casefile-error text-center">
                    <img
                        src={`${images}/graphics/automated.png`}
                        height="300"
                        alt=""
                    />
                    <h1>{i18n`An unexpected error occurred`}</h1>

                    <Button
                        theme="blue"
                        onClick={() => window.location.reload()}>
                        {i18n`Reload page`}
                    </Button>
                </div>
            </div>
        );
    };

    const handleBackButton = () => {
        const path = breadcrumbs[breadcrumbs.length - 2];

        router.push(path.route);
    };

    useEffect(() => {
        const hasBreadcrumbsInitial = breadcrumbs?.length;

        setHasBreadcrumbs(!!hasBreadcrumbsInitial);

        if (!hasBreadcrumbsInitial) {
            dispatch(addBreadcrumbsAction(archiveInitialBreadcrumb()));
        }

        loadData();
    }, [casefileId]);

    useEffect(() => {
        if (caseFile?.isLoaded && caseFile?.data && !attachments?.isLoaded) {
            dispatch(fetchCasefileAttachments(caseFile.data));
        }
    }, [caseFile?.isLoaded, caseFile?.data?.id]);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                await dispatch(fetchCasefile(casefileId));
                await dispatch(fetchCasefileFolder(casefileId));

                if (caseFile?.data) {
                    await dispatch(fetchCasefileAttachments(caseFile.data));
                }
            } catch (error) {
                console.error('Error loading initial data:', error);
            }
        };

        loadInitialData();
    }, [casefileId, dispatch]);

    useEffect(() => {
        const setupFolderBreadcrumbs = async () => {
            if (folder?.data?.id && !loadedBreadcrumbs && caseFile.isLoaded) {
                const currentBreadcrumb = {
                    title: caseFile.data.title,
                    route: {
                        name: 'personal-archive-casefile-details',
                        params: {
                            casefileId: caseFile.data.id,
                        },
                    },
                };

                if (hasBreadcrumbs) {
                    if (
                        !breadcrumbs.some(
                            (crumb) =>
                                crumb.route?.params?.casefileId ===
                                currentBreadcrumb.route.params.casefileId
                        )
                    ) {
                        dispatch(addBreadcrumbsAction(currentBreadcrumb));
                    }
                } else {
                    try {
                        // Get folder predecessors
                        const predecessors = await dispatch(
                            getFolderPredecessors(folder.data.id)
                        );
                        const folderBreadcrumbs =
                            predecessors?.map((folderData) => ({
                                title: folderData.title,
                                route: {
                                    name: 'personal-archive-folder',
                                    params: {
                                        folderId: folderData.id,
                                    },
                                },
                            })) || [];

                        // Update breadcrumbs with full path
                        dispatch(
                            updateBreadcrumbsAction([
                                archiveInitialBreadcrumb(false),
                                ...folderBreadcrumbs,
                                currentBreadcrumb,
                            ])
                        );
                    } catch (error) {
                        // Fallback to root path if predecessor fetch fails
                        dispatch(
                            updateBreadcrumbsAction([
                                archiveInitialBreadcrumb(false),
                                currentBreadcrumb,
                            ])
                        );
                    }
                }

                setLoadedBreadcrumbs(true);
            }
        };

        setupFolderBreadcrumbs();
    }, [folder?.data?.id, caseFile.isLoaded, loadedBreadcrumbs, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(deleteBreadcrumbs());
            dispatch(resetCasefileFolder());
        };
    }, [dispatch]);

    if (caseFile.error) {
        return renderError(caseFile.error);
    }

    if (!caseFile?.isLoaded || !attachments?.isLoaded) {
        return (
            <div className="casefile-details ui-v2">
                <Loading />
            </div>
        );
    }

    const status = getCasefileStatus(caseFile.data, eventLog);

    return (
        <div className="casefile-details ui-v2">
            <Helmet>
                <title>{caseFile.data.title}</title>
            </Helmet>

            <Link className="back-link" onClick={handleBackButton}>
                <i className="fas fa-arrow-left" />
                <div className="back-button-text">&nbsp;{i18n`Back`}</div>
            </Link>

            <a
                className={`pull-right no-underline`}
                rel="noopener noreferrer"
                target="_blank"
                href={`${Constants.PENNEO_ORIGIN}/casefile/${casefileId}/downloadFiles`}
                onClick={trackDownload}>
                <Button
                    theme="blue"
                    variant="outline"
                    icon="far fa-arrow-alt-to-bottom"
                    renderIconLeft={true}>
                    {i18n`Download`}
                </Button>
            </a>

            {modal && <div className="casefile-details-modal">{modal}</div>}

            {caseFile.data.caseFileType && (
                <div className="casefile-signing-flow text-blue">
                    <i className="far fa-tag"></i>&nbsp;
                    {caseFile.data.caseFileType.name}
                </div>
            )}

            <h1 className="casefile-details-title">
                {caseFile.data.title}&nbsp;
            </h1>

            <StatusBanner status={status} />

            <span className="casefile-details-subtitle">
                {caseFile.data.created &&
                    i18n`Created on ${formatDate(caseFile.data.created)}`}
                {caseFile.data.completed && (
                    <span>
                        &nbsp;|&nbsp;
                        {i18n`Completed on ${formatDate(
                            caseFile.data.completed
                        )}`}
                    </span>
                )}{' '}
                | ID: {caseFile.data.id}
            </span>

            <CasefileDocuments
                casefile={caseFile.data}
                documents={caseFile.data.documents}
            />
        </div>
    );
};

const SignersCasefileDetailsWrapper = (props: Props) => {
    useCustomerRedirect({
        router: props.router,
        redirectRoute: Routes.defaultV2RouteName,
        shouldRedirectNonCustomer: false,
    });

    return <SignersCasefileDetails {...props} />;
};

export default connect((state: ReduxState, props: Props) => ({
    caseFile: state.caseFileDetails.casefile,
    eventLog: state.caseFileDetails.eventLog,
    signers: state.caseFileDetails.signers,
    folder: state.caseFileDetails.folder,
    attachments: state.caseFileDetails.attachments,
    breadcrumbs: state.archive.breadcrumbs,
    casefileId: Number(props.params.casefileId),
}))(SignersCasefileDetailsWrapper);
