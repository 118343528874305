import React from 'react';
import { Toast } from '../ToastContext';
import Analytics from 'Common/Analytics';
import UIButton from '../Vega/Button';
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { getUserAccess } from 'Casefiles/utils';

interface ToastProps {
    toast: Toast;
    removeToast: () => void;
}

const ToastComponent: React.FC<ToastProps> = ({ toast, removeToast }) => {
    const { title, message, type, action, closeButtonLabel } = toast;

    const showButtons = !!action || !!closeButtonLabel;

    const { isSignersArchive } = getUserAccess();

    return (
        <div
            data-testid={toast.testId}
            aria-live="assertive"
            className="min-w-[320px] sm:w-80 md:w-96">
            <div className="pointer-events-auto w-full max-w-md space-y-4 rounded bg-white p-5 shadow-light-lg">
                <div className="flex space-x-2">
                    <div className="h-6 w-6 flex-shrink-0">
                        {type === 'success' && (
                            <CheckCircleIcon className="text-success-600" />
                        )}
                        {type === 'warning' && (
                            <InformationCircleIcon className="text-yellow-500" />
                        )}
                        {type === 'accent' && (
                            <InformationCircleIcon className="text-secondary-600" />
                        )}
                        {type === 'neutral' && (
                            <InformationCircleIcon className="text-neutral-800" />
                        )}
                        {type === 'danger' && (
                            <ExclamationTriangleIcon className="text-danger-700" />
                        )}
                    </div>
                    <div className="space-y-2">
                        {title && (
                            <p className="text-sm font-bold m-0">{title}</p>
                        )}
                        <p className="text-sm m-0">{message}</p>
                    </div>
                </div>
                {showButtons && (
                    <div className="flex justify-end space-x-4">
                        {closeButtonLabel && (
                            <UIButton
                                onClick={() => {
                                    Analytics.track(
                                        action?.tracking?.label ??
                                            'Confirm - clicked',
                                        {
                                            source: 'Toast action',
                                            isSignersArchive,
                                        }
                                    );
                                    removeToast();
                                }}
                                priority="tertiary"
                                type={type}
                                size="sm">
                                {closeButtonLabel}
                            </UIButton>
                        )}
                        {action && (
                            <UIButton
                                onClick={() => {
                                    window.location.href = action.url;

                                    if (action.tracking) {
                                        Analytics.track(action.tracking.label, {
                                            ...action.tracking.eventProps,
                                            source: 'Toast action',
                                        });
                                    }

                                    removeToast();
                                }}
                                priority="primary"
                                type={type}
                                size="sm">
                                {action.label}
                            </UIButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToastComponent;
