import { AxiosRequestConfig, CancelToken } from 'axios';
import { SigningAPI } from 'Api';
import { tempPdfFileUploadEndpoint } from '../utils';
import { MediaApi } from 'Api/ApiClient';
import { Languages } from 'Language/Constants';

export type Document = {
    caseFileId?: number;
    documentTypeId?: number;
    filename: string;
    localPath?: string;
    name?: string;
    order?: number;
    base64File?: string;
    file: File;
};

type UploadedDocument = {
    id: number;
    extension: 'pdf' | 'xbrl';
    title: string;
    name: string;
    type: string;
};

const download = (fileName: string, blob: Blob): void => {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);

    link.click();
    link.remove();

    // in case the Blob uses a lot of memory
    window.addEventListener('focus', () => URL.revokeObjectURL(link.href), {
        once: true,
    });
};

/**
 * @param doc - Represents user uploaded document
 * @returns Promise which resolves to an array with one object of the uploaded document
 */
const temporaryPDFUpload = async (
    doc: Document,
    cancelToken?: CancelToken
): Promise<UploadedDocument[]> => {
    const payload = {
        files: [
            {
                title: doc.filename,
                content: doc.base64File
                    ? doc.base64File
                    : await _getBase64EncodedDocument(doc.file),
            },
        ],
    };

    return await SigningAPI.post(tempPdfFileUploadEndpoint(), payload, {
        cancelToken,
    });
};

/**
 * Uploads a PDF file with multipart/form-data. This endpoint accepts iXBRL files.
 * @param doc - Document to upload
 * @param onUploadProgress - Custom function to handle upload progress
 * @returns Promise resolving to the uploaded PDF data
 */
const temporaryPDFUploadMultipart = async (
    doc: Document,
    locale: Languages,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
    cancelToken?: CancelToken
): Promise<UploadedDocument[]> => {
    const formData = new FormData();

    formData.append('file', doc.file);
    formData.append('locale', locale);

    const response = await MediaApi.post('/documents', formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
        onUploadProgress,
        cancelToken,
        raw: true,
    });

    return response.data;
};

/**
 * @param fileId - Represents an ID of the file to download from the temporal storage
 */
const temporaryPDFDownload = async (fileId: number): Promise<Blob> => {
    const options: AxiosRequestConfig = {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return await SigningAPI.get(`/temporal-pdf-files/${fileId}`, null, options);
};

/**
 * @param fileId - Represents an ID of the file to delete
 */
const deleteFile = async (fileId: number): Promise<void> => {
    const options: AxiosRequestConfig = {
        headers: {
            Accept: 'application/json',
        },
    };

    return await SigningAPI.delete(`/files/${fileId}`, null, options);
};

const _arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
};

const _getBase64EncodedDocument = (file?: File): Promise<string> => {
    if (!file) {
        return Promise.reject(new Error('No file provided'));
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
                const base64 = _arrayBufferToBase64(reader.result);

                resolve(base64);
            } else {
                reject(new Error('Failed to read file as ArrayBuffer'));
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
};

export {
    download,
    temporaryPDFUpload,
    temporaryPDFDownload,
    deleteFile,
    temporaryPDFUploadMultipart,
};
