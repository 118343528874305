import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

const DashboardMenuItem = ({ children, branding, name, controls }) => {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);

    const handleClick = () => {
        close();
    };

    const openMenu = () => {
        setOpen(true);
    };

    const close = () => {
        setOpen(false);
    };

    const toggledClick = () => {
        if (open) {
            close();
        } else {
            openMenu();
        }
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const aria = {
        'aria-label': name ?? 'Menu',
        'aria-controls': controls,
        role: 'switch',
        'aria-expanded': open,
        'aria-checked': open,
    };

    return (
        <button
            ref={wrapperRef}
            className={'dashboard-menu-item' + (open ? ' open' : '')}
            onMouseLeave={close}
            onClick={toggledClick}
            {...aria}
            style={{
                borderTopColor: branding?.highlightColor,
            }}>
            {React.cloneElement(children, {
                closeDropdown: handleClick,
                open: open,
            })}
        </button>
    );
};

DashboardMenuItem.propTypes = {
    children: PropTypes.object,
    branding: PropTypes.object,
    name: PropTypes.string,
    controls: PropTypes.string,
};

export default DashboardMenuItem;
