import React from 'react';
import BurgerMenuButton from './BurgerMenuButton';
import { UserEntity as User } from 'types/User';
import { LogoSvg } from '../Logo';
import StatusNotification from '../Common/StatusNotification';
import { useFetchIncidents } from 'Common/hooks/useFetchIncidents';

type Props = {
    user: User;
    branding: { imageUrl: string; backgroundColor: string };
    toggleMenu: () => void;
    open: boolean;
    displaySigner?: boolean;
};

const MobileHeader = ({ toggleMenu, branding, open, displaySigner }: Props) => {
    const incidents = useFetchIncidents();

    const logo =
        branding.imageUrl ||
        'https://penneo.com/wp-content/uploads/2015/11/logo-1.png';

    return (
        <div className="absolute top-0 left-0 w-full h-full select-none">
            <BurgerMenuButton
                onClick={toggleMenu}
                isCollapsed={!open}
                isMobile={true}
                displaySigner={displaySigner}
            />
            <div
                className={`h-[56px] z-1 border-r-0 border-t-0 border-l-0 border-b-[2px] border-[#D1D5DB] border-solid p-1`}
                style={{
                    background: displaySigner
                        ? '#F5F9FF'
                        : branding.backgroundColor,
                }}>
                {!displaySigner && (
                    <div className="h-full py-1 relative text-center w-full">
                        <img className="h-full relative" src={logo} alt="" />
                    </div>
                )}
                {displaySigner && (
                    <div className="m-1 mx-auto text-center text-[#086AFA] w-[131px]">
                        <LogoSvg />
                    </div>
                )}

                <StatusNotification
                    displaySigner={displaySigner}
                    isMobile
                    offset={-30}
                    incidents={incidents ?? []}
                />
            </div>
        </div>
    );
};

export default MobileHeader;
