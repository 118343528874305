import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import ClickDropdown from 'Common/components/Common/ClickDropdown';
import { i18n, TranslationStore } from 'Language';
import Constants from 'Constants';
import { Incident } from 'Common/components/StatusPageMessage/StatusPageMessage';
import {
    AnimatedElement,
    AnimationType,
} from 'Common/components/Common/AnimatedElement';

export type Props = {
    placement?: string;
    offset?: number;
    incidents: Incident[];
    isMobile?: boolean;
    displaySigner?: boolean;
};

const StatusNotification = ({
    placement = 'bottom left',
    offset = -34,
    incidents,
    isMobile = false,
    displaySigner = false,
}: Props) => {
    const renderLastUpdate = (updates: any[] = []) => {
        if (updates.length === 0) {
            return null;
        }

        const update = updates[0]; // most recent update

        return (
            <div>
                <p style={{ whiteSpace: 'pre-line' }}>
                    {update.body}
                    &nbsp;
                    <span className="link">Read more</span>
                </p>
            </div>
        );
    };

    const setMomentLocale = () => {
        let languageCode = TranslationStore.getLanguage();

        if (languageCode === 'no') {
            languageCode = 'nb';
        }

        moment.locale(languageCode);
    };

    const renderContentList = () => {
        setMomentLocale();

        return (
            <div className="incident-list">
                {incidents.length === 0 && (
                    <div className="text-center">
                        {i18n`All Penneo services are working as expected`}
                        &nbsp;
                        <i className="fa fa-check" />
                    </div>
                )}
                {incidents.map((incident) => (
                    <a
                        href={incident.shortlink}
                        key={incident.id}
                        className={`incident no-underline`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className={`label ${incident.status}`}>
                            {incident.status}
                        </span>
                        <span className="time">
                            {moment(incident.updated_at).fromNow()}
                        </span>
                        <h3>{incident.name}</h3>
                        {renderLastUpdate(incident.incident_updates)}
                    </a>
                ))}

                <div className="text-center">
                    <a
                        className="link-bottom"
                        href={Constants.sites.statusPage}
                        target="_blank"
                        rel="noopener noreferrer">
                        {i18n`Visit our status page to sign up for updates`}
                    </a>
                </div>
            </div>
        );
    };

    const getIncidentsState = (incidents: Incident[]) => {
        const statuses = incidents.map((i) => i.status);

        if (
            statuses.includes('investigating') ||
            statuses.includes('identified')
        ) {
            return 'error';
        }

        if (statuses.includes('monitoring')) {
            return 'warning';
        }

        if (statuses.includes('resolved')) {
            return 'success';
        }

        return 'normal';
    };

    const getNotificationIcon = () => {
        const incidentState = getIncidentsState(incidents);
        const classes = ['far notification-icon', 'fa-bell'];
        const unread =
            (incidentState === 'error' || incidentState === 'warning') &&
            'unread';

        return (
            <div className="status-notification-content">
                <AnimatedElement
                    tag="i"
                    className={classNames(
                        [...classes, incidentState, unread],
                        isMobile && displaySigner ? 'text-[#04224C]' : ''
                    )}
                    options={{
                        type: AnimationType.shake,
                        duration: 2,
                        times: unread ? 5 : 0,
                    }}
                />
                {unread && (
                    <AnimatedElement
                        tag="span"
                        className={classNames(
                            'notification-circle',
                            isMobile && displaySigner ? 'text-[#04224C]' : ''
                        )}
                        options={{
                            type: AnimationType.beat,
                            duration: 1.2,
                            easing: 'ease-in-out',
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div
            className="status-notification"
            style={isMobile ? { paddingTop: '0.4rem' } : {}}>
            <ClickDropdown
                placement={placement}
                offset={offset}
                content={renderContentList()}>
                {getNotificationIcon()}
            </ClickDropdown>
        </div>
    );
};

StatusNotification.propTypes = {
    placement: PropTypes.string,
    offset: PropTypes.number,
};

export default StatusNotification;
