import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { i18n, TranslationStore } from 'Language';

// Components
import ActivityGraph from './ActivityGraph';
import StartWorkWidget from './StartWorkWidget';
import ExpiresSoonWidget from './ExpiresSoonWidget';
import CompletedRecentlyWidget from './CompletedRecentlyWidget';
import MyPendingSignaturesWidget from './MyPendingSignaturesWidget';
import { UserEntity } from 'types/User';
import { CustomerEntity } from 'types/Customer';
import { Routes } from 'Constants';
import './dashboard.scss';
import { isV2Signing, signingUrlBase } from 'Signing/utils';
import { notify } from 'react-notify-toast';
import { clearOpenIdState } from 'OpenID/utils/openIdState';
import { AppDispatch, ReduxState } from 'Store';
import { connect } from 'react-redux';
import {
    resetCasefileSavedAsDraft,
    resetCasefileSent,
} from 'Casefiles/Archive/redux/reducer';
import { resettedState } from 'RegisteredLetter/redux/reducer';
import { signingStages } from 'types/SigningProcess';
import { trackDashboardInteraction } from './utils';
import {
    hasCasefileSendAccess,
    hasFormsAccess,
} from 'Common/utils/userPermissions';
import { ToastContext } from '../ToastContext';
import CasefileStore from 'Casefiles/stores/CasefileStore';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import { getUserAccess } from 'Casefiles/utils';

type Props = {
    user: UserEntity;
    customer: CustomerEntity;
    dispatch: AppDispatch;
    casefileSent: boolean;
    registeredletterSent: boolean;
    casefileSaved: boolean;
    router: any;
};

const DashboardsWrapper = (props: Props) => {
    const { router } = props;

    const { isSignersArchive: shouldRedirect } = getUserAccess();

    useCustomerRedirect({
        shouldRedirect,
        shouldRedirectNonCustomer: true,
        redirectRoute: Routes.defaultSignersArchiveRoute,
        router,
    });

    return <Dashboard {...props} />;
};

const Dashboard = ({
    user,
    customer,
    dispatch,
    casefileSent,
    registeredletterSent,
    casefileSaved,
    router,
}: Props) => {
    const { addToast } =
        useContext(ToastContext) ??
        (() => {
            throw new Error('ToastContext not found');
        })();
    const base = signingUrlBase();
    const language = TranslationStore.getLanguage();
    // config for notifications
    const notifyCustom = {
        background: 'transparent',
        text: '#666',
    };

    useEffect(() => {
        if (!casefileSent && !registeredletterSent) return;

        dispatch(resetCasefileSent());
        dispatch(resettedState());

        const isScheduled = CasefileStore.getSendAtDate();

        if (isScheduled) {
            addToast(
                i18n`scheduledCasefileToast.subtitle`,
                i18n`scheduledCasefileToast.title`,
                'accent',
                10000,
                i18n`Ok, got it`,
                {
                    label: i18n`sendCasefileToast.actionButton`,
                    url: `${base}casefiles/manage/scheduled-delivery`,
                    tracking: {
                        label: 'Casefile scheduled for delivery toast',
                        eventProps: {
                            to: 'Scheduled delivery',
                        },
                    },
                },
                'casefile-scheduled-for-delivery-toast'
            );
        } else {
            addToast(
                i18n`sendCasefileToast.subtitle`,
                i18n`sendCasefileToast.title`,
                'accent',
                10000,
                i18n`Ok, got it`,
                {
                    label: i18n`sendCasefileToast.actionButton`,
                    url: `${base}casefiles/manage/pending`,
                    tracking: {
                        label: 'Casefile sent toast',
                        eventProps: {
                            to: 'Pending',
                        },
                    },
                },
                'casefile-sent-toast'
            );
        }
    }, [casefileSent, registeredletterSent, dispatch]);

    useEffect(() => {
        if (casefileSaved) {
            dispatch(resetCasefileSavedAsDraft());

            addToast(
                i18n`saveDraftToast.subtitle`,
                i18n`saveDraftToast.title`,
                'accent',
                10000,
                i18n`Ok, got it`,
                {
                    label: i18n`saveDraftToast.actionButton`,
                    url: `${base}casefiles/manage/drafts`,
                    tracking: {
                        label: 'Casefile saved toast',
                        eventProps: {
                            to: 'Drafts',
                        },
                    },
                },
                'casefile-sent-toast'
            );
        }
    }, [casefileSaved, dispatch]);

    useEffect(() => {
        /**
         * Logic to show sign success/rejection notification
         */
        if (isV2Signing.check()) {
            const stage = isV2Signing.get();

            /**
             * We clear everything related to V2 signing (like OpenID state, etc)
             * and we skip tracking
             */
            if (
                stage !== signingStages.signed &&
                stage !== signingStages.rejected
            ) {
                // cleanup
                isV2Signing.clear();
                clearOpenIdState();

                return;
            }

            notify.show(
                <aside
                    className="casefile-sent-notification"
                    aria-live="assertive">
                    <p className="casefile-sent-notification-heading">
                        {stage === signingStages.signed ? (
                            <>
                                <i
                                    className="fas fa-check-circle"
                                    aria-hidden></i>{' '}
                                {i18n`Your signature has been successfully registered.`}
                            </>
                        ) : (
                            i18n`You have rejected to sign.`
                        )}
                    </p>
                    <p>
                        {i18n`You can find your documents in your`}
                        {` `}
                        <a
                            href={
                                stage === signingStages.signed
                                    ? `${base}casefiles/manage/completed`
                                    : `${base}casefiles/manage/rejected`
                            }
                            onClick={() =>
                                trackDashboardInteraction('navigation', {
                                    source: 'Signing V2 notification',
                                    to: 'archive',
                                    stage: stage,
                                })
                            }>
                            {i18n`archive`}
                        </a>
                    </p>
                </aside>,
                'custom',
                8000,
                notifyCustom
            );

            // makes sure to cleanup, so notification is not shown twice
            isV2Signing.clear();
        }
    }, [base, language]);

    // signers that are not customers can ocasionly hit this route
    const isCustomerActive = customer?.active ?? false;

    const isStartWorkEnabled =
        isCustomerActive &&
        (hasCasefileSendAccess(user) || hasFormsAccess(user));

    return (
        <main id="content" className="dashboard-new">
            <Helmet>
                <title>{i18n`Dashboard`}</title>
            </Helmet>
            <h1 className="sr-only">Dashboard</h1>

            <section className="dashboard-new-widgets-grid">
                <ActivityGraph />
                <StartWorkWidget disabled={!isStartWorkEnabled} user={user} />
                <ExpiresSoonWidget />
                <CompletedRecentlyWidget />
                <MyPendingSignaturesWidget />
            </section>
        </main>
    );
};

export default connect((state: ReduxState) => ({
    casefileSent: state.archive.sent,
    registeredletterSent: state.registeredLetter.view.isSent,
    userSettings: state.settings.data.user,
    casefileSaved: state.archive.savedAsDraft,
}))(DashboardsWrapper);
