import React from 'react';
import { i18n } from 'Language';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';

type UploadProgressIndicatorProps = {
    percentageUploaded?: number;
    isDocumentCompleted: boolean;
};

export const UploadProgressIndicator: React.FC<UploadProgressIndicatorProps> = ({
    percentageUploaded,
    isDocumentCompleted,
}) => {
    const getDisplayedPercentage = (
        percentageUploaded: number | undefined,
        isDocumentCompleted: boolean
    ) => {
        if (percentageUploaded === undefined) return null;

        if (isDocumentCompleted) return 100;

        if (percentageUploaded === 100) return 99;

        return percentageUploaded;
    };

    const isUploading = percentageUploaded !== 100;
    const displayedPercentage = getDisplayedPercentage(
        percentageUploaded,
        isDocumentCompleted
    );

    return (
        <div className="flex items-center whitespace-nowrap">
            <div className="flex gap-1">
                {displayedPercentage !== null && (
                    <span>{displayedPercentage}%</span>
                )}
                <span>
                    {isUploading ? i18n`Uploading` : i18n`Processing`}...
                </span>
            </div>
            <div className="px-2">
                <UILoadingSpinner variant="accent" />
            </div>
        </div>
    );
};
