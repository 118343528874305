import { SigningAPI } from 'Api';
import {
    ENVIRONMENTS,
    TEMPLATE_ENTITY_KIND,
    TEMPLATE_ENTITY_NAME,
} from 'Constants';
import { convertFileContentToBase64 } from 'RegisteredLetter/utils';
import {
    TemplateEntities,
    TemplateEntityKind,
    TemplateEntityName,
    TemplateType,
} from 'types/EmailTemplates';
import { NewCasefileState } from './redux/newCaseFile/types';
import { CaseFileEntity } from 'types/CaseFile';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { AuthStore } from 'Auth';
import launchDarkly from 'Common/LaunchDarkly';
import { getEnvironment } from '../utils';

/**
 * Sorts array by selected key. (Doesn't clone array)
 * @param arr {Array}  Array to be shifted
 * @param key {string} Key to do the sorting by
 */
function sortArrayByKey(arr, key) {
    arr.sort(function (a, b) {
        let keyA = a[key];
        let keyB = b[key];

        if (typeof keyA === 'string') {
            keyA = keyA.toLowerCase();
        }

        if (typeof keyB === 'string') {
            keyB = keyB.toLowerCase();
        }

        // Compare the 2 values
        if (keyA < keyB) {
            return -1;
        } else if (keyA > keyB) {
            return 1;
        }

        return 0;
    });
}

function removeDuplicates(arr, key) {
    // extract keys
    let keys = {};

    for (let i = 0; i < arr.length; i++) {
        let value = arr[i][key];

        if (keys.hasOwnProperty(value)) {
            continue;
        }

        keys[value] = i;
    }

    let values: any[] = [];

    for (let index in keys) {
        if ({}.hasOwnProperty.call(keys, index)) {
            values.push(arr[keys[index]]);
        }
    }

    return values;
}

export let utils = {
    sortArrayByKey: sortArrayByKey,
    removeDuplicates: removeDuplicates,
};

export const uploadTemporaryDocuments = async (documents: File[]) => {
    const payload = await Promise.all(
        documents.map(async (document) => {
            return {
                content: await convertFileContentToBase64(document),
                title: document.name,
            };
        })
    );

    const response: {
        extension: 'pdf' | 'xbrl';
        id: number;
        name: string;
        title: string;
        type: string;
    }[] = await SigningAPI.post(tempPdfFileUploadEndpoint(), {
        files: payload,
    });

    return response;
};

export function fetchTemplateEntities(
    config:
        | {
              casefileId: number;
              signingRequestIds?: number[];
          }
        | {
              signingRequestIds: number[];
              casefileId?: number;
          }
): Promise<TemplateEntities> {
    const { casefileId, signingRequestIds } = config;

    if (!casefileId && (!signingRequestIds || signingRequestIds.length < 1)) {
        // TODO: Log this to error tracker
        throw new Error('No casefile ID or signing request IDs');
    }

    const entityRelations: { name: TemplateEntityName; ids: number[] }[] = [];

    if (casefileId) {
        entityRelations.push({
            name: TEMPLATE_ENTITY_NAME.CASEFILE,
            ids: [casefileId],
        });
    }

    if (signingRequestIds && signingRequestIds.length > 0) {
        entityRelations.push({
            name: TEMPLATE_ENTITY_NAME.SIGNING_REQUEST,
            ids: signingRequestIds,
        });
    }

    const kinds = [
        TEMPLATE_ENTITY_KIND.COMPLETED_BODY,
        TEMPLATE_ENTITY_KIND.COMPLETED_SUBJECT,
        TEMPLATE_ENTITY_KIND.REMINDER_BODY,
        TEMPLATE_ENTITY_KIND.REMINDER_SUBJECT,
        TEMPLATE_ENTITY_KIND.REQUEST_BODY,
        TEMPLATE_ENTITY_KIND.REQUEST_SUBJECT,
    ];

    return SigningAPI.post(`/entity-templates/compact-templates/lookup`, {
        lookups: [{ entityRelations, kinds }],
    });
}

export async function replaceTemplateEntity(
    content: string,
    kind: TemplateEntityKind,
    name: TemplateEntityName,
    relationIds: number[],
    uuid: string
) {
    return SigningAPI.put(
        `/entity-templates/compact-templates/${kind}/${uuid}?force=true`,
        { content, entityRelations: [{ name, relationIds }], kind, uuid }
    );
}

async function postTemplateEntities(
    content: string,
    kind: TemplateEntityKind,
    relations: { name: TemplateEntityName; ids: number[] }
) {
    return SigningAPI.post('/entity-templates/compact-templates?force=true', {
        kind,
        content,
        entityRelations: [{ name: relations.name, ids: relations.ids }],
    });
}

export async function deleteTemplateEntity(
    kind: TemplateEntityKind,
    uuid: string
) {
    return SigningAPI.delete(`/entity-templates/templates/${kind}/${uuid}`);
}

export async function updateTemplateEntities(
    casefileId: number,
    emailMessages: NewCasefileState['emailMessages'],
    signers: { tempId: string; signingRequestId: number }[]
) {
    const templateKindMap = [
        {
            type: TemplateType.SIGNER,
            bodyKind: TEMPLATE_ENTITY_KIND.REQUEST_BODY,
            subjectKind: TEMPLATE_ENTITY_KIND.REQUEST_SUBJECT,
        },
        {
            type: TemplateType.REMINDER,
            bodyKind: TEMPLATE_ENTITY_KIND.REMINDER_BODY,
            subjectKind: TEMPLATE_ENTITY_KIND.REMINDER_SUBJECT,
        },
        {
            type: TemplateType.COMPLETED,
            bodyKind: TEMPLATE_ENTITY_KIND.COMPLETED_BODY,
            subjectKind: TEMPLATE_ENTITY_KIND.COMPLETED_SUBJECT,
        },
    ];

    // For each casefile specific message
    for (const { type, bodyKind, subjectKind } of templateKindMap) {
        const { body, subject } = emailMessages.general.messages[type];

        await Promise.all([
            postTemplateEntities(body.content, bodyKind, {
                name: TEMPLATE_ENTITY_NAME.CASEFILE,
                ids: [casefileId],
            }),
            postTemplateEntities(subject.content, subjectKind, {
                name: TEMPLATE_ENTITY_NAME.CASEFILE,
                ids: [casefileId],
            }),
        ]);
    }

    // For each signing request specific message
    for (const message of emailMessages.custom) {
        const signingRequestIds = message.recipients.map((recipient) => {
            return signers.find((signer) => signer.tempId === recipient.tempId)!
                .signingRequestId;
        });

        if (signingRequestIds.length < 1) return;

        await Promise.all([
            postTemplateEntities(
                message.messages.signing_request.subject.content,
                TEMPLATE_ENTITY_KIND.REQUEST_SUBJECT,
                {
                    name: TEMPLATE_ENTITY_NAME.SIGNING_REQUEST,
                    ids: signingRequestIds,
                }
            ),
            postTemplateEntities(
                message.messages.signing_request.body.content,
                TEMPLATE_ENTITY_KIND.REQUEST_BODY,
                {
                    name: TEMPLATE_ENTITY_NAME.SIGNING_REQUEST,
                    ids: signingRequestIds,
                }
            ),
        ]);
    }
}

// Used for tracking
export function aggregateEmailMessages(
    messages: NewCasefileState['emailMessages']
) {
    const { general, custom } = messages;

    // The reason why ID could be unavailable here is because we lose that data
    // whenever a user saves a casefile as a draft.
    function parseId(id?: number) {
        return !id ? 'N/A' : id < 0 ? 'Default' : 'Non-default';
    }

    return {
        generalMsgTmpltRequest: parseId(general.messages.signing_request.id),
        generalMsgTmpltReminder: parseId(general.messages.reminder.id),
        generalMsgTmpltCompleted: parseId(general.messages.completed.id),
        customMessageCount: custom.length,
    };
}

/**
 * Temporary function to redirect file uploads to a different ingress endpoint which permit large file uploads up to
 * 100MB
 */
export const tempPdfFileUploadEndpoint = () => {
    if (launchDarkly.variation(Flags.LARGE_FILE_UPLOAD)) {
        switch (getEnvironment()) {
            case ENVIRONMENTS.PRODUCTION:
                return `${window.location.protocol}//beta.app.penneo.com/api/v3/temporal-pdf-files`;
            case ENVIRONMENTS.SANDBOX:
                return `${window.location.protocol}//beta.sandbox.penneo.com/api/v3/temporal-pdf-files`;
            case ENVIRONMENTS.STAGING:
                return `${window.location.protocol}//beta.staging.penneo.com/api/v3/temporal-pdf-files`;
        }
    }

    return '/temporal-pdf-files';
};

/**
 * Basic ownership checking for casefiles
 */
export const isCasefileOwner = (casefile: CaseFileEntity) => {
    /**
     * If the feature flag is on,
     * we check if the current user is the owner
     * of this casefile (by comparing their customer id with
     * that of the casefile)
     */
    let isOwner = true;

    if (LaunchDarkly.variation(Flags.SIGNER_VIEW)) {
        const currentUser = AuthStore.getAuthDetails();

        isOwner = currentUser.cid === casefile.customerId;
    }

    return isOwner;
};

/**
 * Gets the user access level (customer or signer)
 */
export const getUserAccess = () => {
    const userDetails = AuthStore.getUser();
    const isCustomer = Boolean(userDetails?.customerIds?.length);
    const isSignersArchive = Boolean(
        !isCustomer && LaunchDarkly.variation(Flags.ENABLE_SIGNERS_ARCHIVE)
    );

    return { userDetails, isCustomer, isSignersArchive };
};
