import React, { useState } from 'react';
import { CaseFileItem } from '../Archive';
import { i18n } from 'Language';
import Tooltip from 'Common/components/Tooltip';
import { fetchFolderOwnerName } from '../redux/reducer';
import Button from 'Common/components/Button';
import { UserIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from 'Store';

export interface Props {
    folder: CaseFileItem;
    tooltipClassName?: string;
}

const TooltipFolderOwner: React.FC<Props> = ({ folder, tooltipClassName }) => {
    const [ownerName, setOwnerName] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const fetchOwnerName = async () => {
        const owner = await dispatch(fetchFolderOwnerName(folder.id));

        setOwnerName(owner.fullName);
    };

    const handleMouseEnter = () => {
        if (!ownerName) {
            fetchOwnerName();
        }
    };

    return (
        <div onMouseEnter={handleMouseEnter}>
            <Tooltip
                className={`${tooltipClassName} inline-flex justify-center items-center`}
                showArrow={true}
                horizontalOffset={0}
                text={
                    <div className="whitespace-nowrap text-center">
                        {i18n`Administrator`}:<br />
                        {ownerName ? (
                            <span>{ownerName}</span>
                        ) : (
                            <i className="fas fa-sync-alt fa-spin" />
                        )}
                    </div>
                }>
                <Button variant="text" theme="gray" className="h-8 w-8 p-1.5">
                    <UserIcon className="h-5"></UserIcon>
                </Button>
            </Tooltip>
        </div>
    );
};

export default TooltipFolderOwner;
