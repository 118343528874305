import React from 'react';
import { Route, IndexRoute } from 'react-router';
import CasefileDocumentDetails from 'Casefiles/CasefileDetails/CasefileDocumentDetails';
import ArchiveFolderShare from 'Casefiles/Archive/Share';
import { dispatch } from 'Store';
import { deleteBreadcrumbs } from 'Casefiles/Archive/redux/reducer';
import Archive from 'Casefiles/Archive';
import SignersCasefileDetails from 'SignersArchive/Components/SignersCasefileDetails';
import { PERSONAL_ARCHIVE_ROUTE_NAMES } from 'Constants';

const resetBreadcrumbState = () => {
    dispatch(deleteBreadcrumbs());
};

const clearFilters = () => {
    window?.sessionStorage?.removeItem('archive-search');
    window?.sessionStorage?.removeItem('archive-last-page');
};

const userRoutes = (
    <Route
        name={PERSONAL_ARCHIVE_ROUTE_NAMES.mainRoute}
        path="personal-archive"
        onLeave={() => {
            resetBreadcrumbState();
            clearFilters();
        }}>
        <IndexRoute
            name={PERSONAL_ARCHIVE_ROUTE_NAMES.allRoute}
            component={Archive}
        />
        {/* Folders */}
        <Route
            name={PERSONAL_ARCHIVE_ROUTE_NAMES.folderRoute}
            path="folder/:folderId"
            component={Archive}>
            <Route
                name={PERSONAL_ARCHIVE_ROUTE_NAMES.folderShareRoute}
                path="share"
                components={{
                    modal: ArchiveFolderShare,
                }}
            />
        </Route>
        <Route
            name={PERSONAL_ARCHIVE_ROUTE_NAMES.searchRoute}
            path="search"
            component={Archive}
        />
        <Route
            name={PERSONAL_ARCHIVE_ROUTE_NAMES.virtualRoute}
            path=":tab"
            component={Archive}
        />
        {/* Casefile details */}
        <Route
            name={PERSONAL_ARCHIVE_ROUTE_NAMES.casefileDetailsRoute}
            path="view/:casefileId/details"
            component={SignersCasefileDetails}>
            <Route
                name={PERSONAL_ARCHIVE_ROUTE_NAMES.documentDetailsRoute}
                path="document/:documentId"
                components={{ modal: CasefileDocumentDetails }}
            />
        </Route>
    </Route>
);

const routing = { userRoutes };

export default routing;
