import React from 'react';
import lodash from 'lodash';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { AppDispatch } from 'Store';
import { notify } from 'react-notify-toast';
import { batchActions } from 'redux-batched-actions';
import moment from 'moment';
import { i18n } from 'Language';
import { Checkbox } from 'Common/components';
import { modal } from 'Common/components/Common/Modal';
import ItemModal from './ItemModal';
import ModalConfirm from './ModalConfirm';
import TopActions from './TopActions';
import EmptyTableBody from './EmptyTableBody';
import CaseFileTableTitleItem from './CaseFileTableTitleItem';
import CaseFileTypeItem from './CaseFileTypeItem';
import Table from 'Common/components/BaseTable';
import { ReduxState } from 'Store';
import Move, { OnMove } from './Move';
import {
    caseFilesItemIdPrefix,
    fromPrefixToObject,
    renderCasefileStatus,
} from './utils';
import {
    getTopFolders,
    getFolderChildren,
    Breadcrumbs as BreadcrumbsState,
    setDefaultFolder,
    deleteCaseFilesItems,
    RequestFoldersParamsOptions,
    moveFoldersAndCaseFiles,
    getCaseFiles,
    resetCaseFileItemsError,
    fetchCaseFileStats,
    updateBreadcrumbsAction,
    archiveInitialBreadcrumb,
    getFolderPredecessors,
    unshareSelfFromFolder,
    fetchSharedFolders,
    fetchSharedFoldersStats,
    fetchFolderOwnerName,
} from './redux/reducer';
import {
    ACCESS_FULL,
    ACCESS_READONLY,
    ACCESS_READ_WRITE,
    API_RESPONSE_HEADERS,
    Routes,
    RouteNames,
    PERSONAL_ARCHIVE_ROUTE_NAMES,
    ARCHIVE_ROUTE_NAMES,
    StorageKeys,
} from 'Constants';
import {
    ArchiveRoutes,
    CaseFileStatus,
    SignersArchiveRoutes,
    ArchiveTabConfig,
} from '../Constants';

import ArchiveTabs from './Tabs';
import { changeArchiveLimitAction } from 'Common/redux/Preferences';
import Analytics from 'Common/Analytics';
import UserStore from 'Auth/stores/UserStore';
import { SimpleFolderEntity } from 'types/Folder';
import useCustomerRedirect from 'Common/hooks/useCustomerRedirect';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import { getUserAccess } from 'Casefiles/utils';
import UIBanner from 'Common/components/Vega/Banner';
import RocketLaunchIcon from '@heroicons/react/20/solid/RocketLaunchIcon';

export const Type = {
    Folder: 'folder',
    CaseFile: 'casefile',
    Unshareable: 'unshareable',
    Other: 'other',
};

export type CaseFileItem = {
    id: number;
    shared: boolean;
    title: string;
    type: string;
    caseFileType?: CaseFileTypeEntity;
    accessLevel:
        | typeof ACCESS_FULL
        | typeof ACCESS_READONLY
        | typeof ACCESS_READ_WRITE;
    created?: number;
    completed?: number;
    status?: number;
    folderType?: string;
    reference?: string;
    parentId?: number;
    userId?: number;
    sendAt?: number;
    parentShared?: boolean;
};

type CaseFileTypeEntity = {
    id: number;
    name: string;
};

type FolderPredecessorsType = {
    level: number;
    shared: boolean;
    id: number;
    title: string;
};

type Props = {
    items: CaseFileItem[];
    itemCount: number;
    sharedFolders: SimpleFolderEntity[];
    sharedFoldersCount: number;
    archiveLimitItems: number;
    breadcrumbs: BreadcrumbsState;
    dispatch: AppDispatch & any;
    isLoading: boolean;
    moveFoldersAndCaseFiles: OnMove;
    getArchiveDataError: null | string | number;
    folderId?: number;
    tab?: string;
    [x: string]: any;
    routeNames: RouteNames;
    routes: ArchiveTabConfig[];
};

type CompState = {
    displayMoveContainer: boolean;
    selectedItems: { [x: string]: boolean };
    displayShareModal?: boolean;
    search: string;
    isCheckingSharedFolder?: number;
    isSharedFolder: boolean;
    folderPredecessors: FolderPredecessorsType[];
    hasSharedPredecessors: boolean;
    showBanner: boolean;
    folderOwnerId: number | undefined;
};

const defaultSort = '-created';

const ArchiveWrapper = (props) => {
    // if user has customer but tries to access signers archive - redirect to default route
    const isSignersArchivePath = props.router.location.pathname.includes(
        `/${Routes.defaultSignersArchiveRoute}`
    );
    const shouldRedirect =
        isSignersArchivePath &&
        LaunchDarkly.variation(Flags.ENABLE_SIGNERS_ARCHIVE);

    useCustomerRedirect({
        shouldRedirect,
        router: props.router,
        redirectRoute: Routes.defaultV2RouteName,
        shouldRedirectNonCustomer: false,
    });

    const { isSignersArchive } = getUserAccess();
    const routeNames = isSignersArchive
        ? PERSONAL_ARCHIVE_ROUTE_NAMES
        : ARCHIVE_ROUTE_NAMES;

    const routes = isSignersArchive ? SignersArchiveRoutes : ArchiveRoutes;

    return <Archive {...props} routes={routes} routeNames={routeNames} />;
};

class Archive extends React.Component<Props, CompState> {
    cachedFolderId: string = '';
    selectedFolderId: number | null = null;
    selectedFolderName: string = '';
    lastRoutePath: string = '';
    // @see https://stackoverflow.com/questions/45802988/typescript-use-correct-version-of-settimeout-node-vs-window/56239226#56239226
    searchTimeOut: ReturnType<typeof setTimeout> | undefined = undefined;
    userDetails: any;
    isSignersArchive: boolean;
    folderPredecessors: FolderPredecessorsType[] = [];
    hasSharedPredecessors: boolean = false;
    folderOwnerId: number | undefined = undefined;

    // @note: This was converted to a function instead of a class property to assist with
    // live reloading in development. Class properties don't get reevaluated until the app is
    // restarted
    getTopLevelTableConfig = () => {
        return {
            checkbox: {
                thClassName: 'column-checkbox',
                tdClassName: 'column-checkbox',
                disableSort: true,
                disableBubbling: true,
                thComponent: () => (
                    <Checkbox
                        onChange={this.handleOnDeselectAllCheckbox}
                        checked={!!Object.keys(this.state.selectedItems).length}
                    />
                ),
                component: (item: CaseFileItem) => (
                    <Checkbox
                        onChange={this.handleSelection(item)}
                        checked={
                            this.state.selectedItems[
                                caseFilesItemIdPrefix(item)
                            ]
                        }
                        disabled={
                            !this.userCanEditItem(
                                item.accessLevel,
                                item.userId,
                                item.shared,
                                this.state.folderOwnerId
                            )
                        }
                    />
                ),
            },
            title: {
                label: i18n('Name'),
                tdClassName: 'column-title py-2 h-12',
                component: (item: CaseFileItem) => (
                    <CaseFileTableTitleItem
                        item={item}
                        getItemRoute={this.getItemRoute}
                        onEditTitle={this.handleOnEditItemTitle}
                        onPreferred={this.handleOnPreferred}
                        onShare={this.handleOnShare}
                        onUnshare={this.handleOnUnshareModal}
                        clearSearch={this.clearSearch}
                        router={this.props.router}
                    />
                ),
            },
            type: {
                thClassName: 'column-type',
                tdClassName: 'column-type',
                label: i18n('Type'),
                component: (item: CaseFileItem) => (
                    <CaseFileTypeItem
                        item={item}
                        userId={this.userDetails.id}
                    />
                ),
            },
        };
    };

    getSignersArchiveTableConfig = () => ({
        ...this.getTopLevelTableConfig(),
        status: {
            thClassName: 'column-status',
            tdClassName: 'column-status',
            label: i18n('Status'),
            component: renderCasefileStatus,
        },
        ...{
            completed: {
                thClassName: 'column-created',
                tdClassName: 'column-created',
                label: i18n('Completed'),
                component: (item: CaseFileItem) => (
                    <div>
                        {item.completed
                            ? moment.unix(item.completed).format('YYYY-MM-DD')
                            : '- - -'}
                    </div>
                ),
            },
        },
    });

    getExtendedTableConfig = () => ({
        ...this.getTopLevelTableConfig(),
        ...{
            status: {
                thClassName: 'column-status',
                tdClassName: 'column-status',
                label: i18n('Status'),
                component: renderCasefileStatus,
            },
            created: {
                thClassName: 'column-created',
                tdClassName: 'column-created',
                label: i18n('Created'),
                component: (item: CaseFileItem) => (
                    <div>
                        {item.created
                            ? moment.unix(item.created).format('YYYY-MM-DD')
                            : '- - -'}
                    </div>
                ),
            },
            completed: {
                thClassName: 'column-created',
                tdClassName: 'column-created',
                label: i18n('Completed'),
                component: (item: CaseFileItem) => (
                    <div>
                        {item.completed
                            ? moment.unix(item.completed).format('YYYY-MM-DD')
                            : '- - -'}
                    </div>
                ),
            },
        },
    });

    constructor(props: Props) {
        super(props);

        const { userDetails, isSignersArchive } = getUserAccess();

        this.userDetails = userDetails;
        this.isSignersArchive = isSignersArchive;
        const bannerHidden =
            localStorage.getItem(StorageKeys.HIDE_ARCHIVE_BANNNER) === 'true';

        this.state = {
            search: window?.sessionStorage?.getItem('archive-search') ?? '',
            selectedItems: {},
            displayMoveContainer: false,
            isCheckingSharedFolder: undefined,
            isSharedFolder: this.props.tab === 'shared',
            folderOwnerId: undefined,
            folderPredecessors: [],
            hasSharedPredecessors: false,
            showBanner: !bannerHidden,
        };
    }

    componentDidMount() {
        const { dispatch, location, router } = this.props;

        const currentPath = location.pathname + location.search;

        this.lastRoutePath = currentPath;

        this.getArchiveData();

        dispatch(fetchCaseFileStats());

        dispatch(fetchSharedFoldersStats());

        this.updateBreadcrumbs();

        const lastLocation = window?.sessionStorage?.getItem(
            'archive-last-page'
        );

        if (lastLocation) {
            router.push(JSON.parse(lastLocation));
        }
    }

    async componentDidUpdate(prevProps: Props, prevState: CompState) {
        const { location, getArchiveDataError, folderId, tab } = this.props;
        const { isSharedFolder, isCheckingSharedFolder } = this.state;

        const didSearchChange = this.state.search !== prevState.search;

        if (getArchiveDataError !== null) {
            notify.show(
                <span>{i18n`There was an error retrieving this data. Please try again`}</span>,
                'error',
                10000
            );
            this.props.dispatch(resetCaseFileItemsError());
        }

        if (didSearchChange) {
            return this.applySearch();
        }

        const currentPath = location.pathname + location.search;
        const prevPath =
            prevProps.location.pathname + prevProps.location.search;

        // If location changed.
        if (currentPath !== prevPath) {
            this.updateBreadcrumbs();

            return this.getArchiveData();
        }

        // If per page changed
        if (prevProps.archiveLimitItems !== this.props.archiveLimitItems) {
            return this.getArchiveData();
        }

        // if it's a folder, we check if it's shared or not
        if (!folderId) {
            // If we were on a shared folder and navigated back to 'all', reset everything
            isSharedFolder &&
                tab !== 'shared' &&
                this.setState({
                    isCheckingSharedFolder: undefined,
                    isSharedFolder: false,
                });

            return;
        }

        if (isCheckingSharedFolder === folderId) {
            return;
        }

        this.setState({ isCheckingSharedFolder: folderId }, () => {
            this.checkIsSharedFolder(folderId);
        });
    }

    checkIsSharedFolder = async (folderId: number) => {
        const { dispatch } = this.props;
        const owner = await dispatch(fetchFolderOwnerName(folderId));

        this.setState(
            {
                isSharedFolder: owner.id !== this.userDetails.id,
                folderOwnerId: owner.id,
            },
            () => {
                this.updateBreadcrumbs();
            }
        );
    };

    updateBreadcrumbs = async () => {
        const { dispatch, tab, folderId } = this.props;
        const { isSharedFolder } = this.state;

        // If virtual folder
        if (tab) {
            const routes = this.props.routes;
            const item = routes.filter(
                (item) => item.route.params.tab === tab
            )[0];
            const breadcrumbs = [
                {
                    title: item.label,
                    route: item.route,
                },
            ];

            return dispatch(updateBreadcrumbsAction(breadcrumbs));
        }

        if (folderId) {
            const predecessors = await dispatch(
                getFolderPredecessors(folderId)
            );

            const breadcrumbs = predecessors.map((folder) => ({
                title: folder.title,
                route: {
                    name: this.props.routeNames.folderRoute,
                    params: {
                        folderId: folder.id,
                    },
                },
            }));

            return dispatch(
                updateBreadcrumbsAction([
                    archiveInitialBreadcrumb(isSharedFolder),
                    ...breadcrumbs,
                ])
            );
        }

        return dispatch(
            updateBreadcrumbsAction([archiveInitialBreadcrumb(isSharedFolder)])
        );
    };

    getVirtualFolderParams = () => {
        const { tab } = this.props;

        if (!tab) {
            return false;
        }

        const routes = this.props.routes;

        const route = routes.filter((item) => item.route.params.tab === tab)[0];

        return route.query;
    };

    getArchiveData = async (requestParams?: RequestFoldersParamsOptions) => {
        const {
            dispatch,
            location,
            tab,
            folderId,
            archiveLimitItems,
        } = this.props;

        this.setState({ selectedItems: {} });

        const params = this.transformStateToRequestFoldersParams({
            per_page: archiveLimitItems,
            page: location.query.page || 0,
            sort: location.query.sort,
            status: location.query.status,
            ...this.state,
            ...requestParams,
        });

        let actions: any = [];

        // If custom search
        if (
            this.props.route.name === 'archive-search' ||
            this.props.route.name === 'personal-archive-search'
        ) {
            actions = [getCaseFiles(params)];

            return dispatch(batchActions(actions));
        }

        // If virtual folder
        if (tab) {
            const virtualTabParams = this.getVirtualFolderParams();
            const cleanParams = lodash.pickBy(
                params,
                (value) => typeof value !== 'undefined'
            );
            const queryParams = {
                ...virtualTabParams,
                ...cleanParams,
                subType: tab,
            };

            actions = [
                tab === 'shared'
                    ? fetchSharedFolders(queryParams)
                    : getCaseFiles(queryParams),
            ];

            return dispatch(batchActions(actions));
        }

        // If folder
        if (folderId) {
            actions = [getFolderChildren(Number(folderId), params)];

            if (this.isSignersArchive) {
                const predecessors = await dispatch(
                    getFolderPredecessors(folderId)
                );

                this.folderPredecessors = predecessors;
                this.hasSharedPredecessors =
                    this.folderPredecessors?.some((pred) => pred.shared) ||
                    false;
            }

            return dispatch(batchActions(actions));
        }

        params.sort = location.query.sort || 'title';
        // If at root of archive.
        actions = [getTopFolders(params)];

        // @todo: If the path changed (i.e. user navigated to another tab or to a folder) clear search terms before fetching new data

        return dispatch(batchActions(actions));
    };

    getItemRoute = (item: CaseFileItem) => {
        if (item.type === Type.Folder) {
            return {
                name: this.props.routeNames.folderRoute,
                params: {
                    folderId: item.id,
                },
            };
        }

        if (item.status === CaseFileStatus.DRAFT) {
            return {
                name: 'casefile-create',
                params: {
                    action: 'draft',
                    casefileId: item.id,
                },
            };
        }

        return {
            name: this.props.routeNames.casefileDetailsRoute,
            params: {
                casefileId: item.id,
            },
        };
    };

    getActiveRoute = () => {
        const { params } = this.props;
        const routes = this.props.routes;

        if (params.tab) {
            const result = routes.filter(
                (item) => item.route.params.tab === params.tab
            )[0];

            if (result) {
                return result.route;
            }
        }

        return routes[0].route;
    };

    handleDeleteItems = async () => {
        const {
            dispatch,
            location,
            items,
            itemCount,
            archiveLimitItems,
            folderId,
        } = this.props;
        const { selectedItems } = this.state;

        const page = Number(location.query.page) || 1;
        const allPages = Math.ceil(itemCount / archiveLimitItems);

        const ids = Object.keys(selectedItems).map(fromPrefixToObject);

        const count = ids.length;

        /**
         * EDGE CASE!
         * There's a bit of an unexpected behavior: you can select
         * the default folder and 'successfully' delete it (it won't error),
         * but in reality it cannot be deleted. This means that 'deleting all selected'
         * won't work on this particular page.
         * In order to prevent unexpected behavior (like navigating to the previous page
         * if we are currently in the last), we need to know
         * if the default folder is selected and not count it
         */
        const defaultFolder = items.filter(
            (item) => item.folderType === Type.Unshareable
        )?.[0];
        const isDefaultFolderSelected = !!(
            defaultFolder &&
            ids.find(
                (id) => id.type === Type.Folder && id.id === defaultFolder.id
            )
        );

        // cleanup
        this.setState({ selectedItems: {} });

        /**
         * We hide the confirmation modal, as triggering the deletion
         * of items will show a loader on the archive table.
         * Otherwise, the modal will be there with an active 'Delete' button
         * until the whole request ends (which can take some time)
         */
        modal.hide();

        try {
            await dispatch(deleteCaseFilesItems(ids, folderId));

            Analytics.track('archive - remove items', {
                count: count,
                cases: ids
                    .filter((item) => item.type === 'casefile')
                    .map((item) => item.id),
                folders: ids
                    .filter((item) => item.type === 'folder')
                    .map((item) => item.id),
                isSignersArchive: this.isSignersArchive,
            });

            notify.show(
                <span>
                    {count > 1
                        ? i18n`Deleted ${
                              isDefaultFolderSelected ? count - 1 : count
                          } items`
                        : i18n(`Deleted item`)}
                </span>,
                'success',
                3000
            );

            /**
             * When deleting all current items in the list,
             * if we are on the last page, we go to the
             * previous page (unless there is only one page available).
             * EDGE CASE: also, we have checked if the default folder is selected.
             * If so, stay on the current page.
             */
            if (
                count === items.length &&
                page === allPages &&
                page > 1 &&
                !isDefaultFolderSelected
            ) {
                this.handleOnPageChange(page - 1);

                return;
            }

            this.getArchiveData();

            // refetch stats to update casefiles count in each tab
            dispatch(fetchCaseFileStats());
        } catch {
            notify.show(
                <span>
                    {count > 1
                        ? i18n`Could not delete ${count} items`
                        : i18n(`Could not delete this item`)}
                </span>,
                'error',
                6000
            );
        }
    };

    handleDeleteItemsModal = () => {
        const ids = Object.keys(this.state.selectedItems).map(
            fromPrefixToObject
        );

        const count = ids.length;

        modal.show({
            body: (
                <ModalConfirm
                    message={i18n(
                        'Are you sure you want to delete the selected items?'
                    )}
                    confirmButtonText={
                        count === 1
                            ? i18n('Delete')
                            : i18n`Delete ${count} items`
                    }
                    onCancel={modal.hide}
                    onOk={this.handleDeleteItems}
                />
            ),
        });
    };

    handleSelection = (item: CaseFileItem) => (checked: boolean) => {
        const selectedItems = { ...this.state.selectedItems };

        if (!checked && selectedItems[caseFilesItemIdPrefix(item)]) {
            delete selectedItems[caseFilesItemIdPrefix(item)];
        } else {
            selectedItems[caseFilesItemIdPrefix(item)] = checked;
        }

        this.setState({ selectedItems });
    };

    handleOnNewFolder = () =>
        modal.show({
            title: i18n('New folder'),
            body: (
                <ItemModal
                    inputLabel={i18n('New folder')}
                    parentId={this.props.folderId}
                    closeModal={modal.hide}
                />
            ),
        });

    transformStateToRequestFoldersParams({
        per_page,
        page,
        search,
        sort,
        status,
    }: RequestFoldersParamsOptions & { search?: string }) {
        /* eslint camelcase:0 */
        const params: RequestFoldersParamsOptions = {
            per_page,
            page,
            sort: sort || defaultSort,
        };

        if (typeof status !== undefined) {
            params.status = status;
        }

        if (typeof search !== undefined) {
            params.title = search;
        }

        return params;
    }

    handleOnEditItemTitle = (item: CaseFileItem) =>
        modal.show({
            title: i18n('Edit title'),
            body: (
                <ItemModal
                    inputLabel={i18n('Edit title')}
                    parentId={this.selectedFolderId ?? undefined}
                    closeModal={modal.hide}
                    item={item}
                />
            ),
        });

    handleOnUnshareModal = (folder: CaseFileItem) => {
        modal.show({
            body: (
                <ModalConfirm
                    message={i18n(
                        'Are you sure you want to remove this folder?'
                    )}
                    helperMessage={i18n(
                        "The folder won’t be deleted from the owner's archive, but you will no longer have access to it."
                    )}
                    confirmButtonText={i18n('Remove')}
                    onCancel={modal.hide}
                    onOk={() => this.handleUnshare(folder)}
                />
            ),
        });
    };

    async handleUnshare(folder: CaseFileItem) {
        const { dispatch } = this.props;

        try {
            await dispatch(unshareSelfFromFolder(folder));

            notify.show(
                <span>{i18n(`Folder successfully removed`)}</span>,
                'success',
                3000
            );
        } catch (error) {
            const { TRACE_ID, REQUEST_ID } = API_RESPONSE_HEADERS;
            const traceId: string = error.headers[TRACE_ID];
            const requestId: string = error.headers[REQUEST_ID];
            const timestamp: string = moment().format('DD/MM hh:mm');

            const message = this.renderErrorMessage(
                requestId,
                traceId,
                timestamp
            );

            notify.show(message, 'error', 10000);
        }

        modal.hide();
    }

    renderErrorMessage(requestId: string, traceId: string, timestamp: string) {
        return (
            <div>
                <span>{i18n(`Could not remove folder`)}</span>
                <div className="mt">
                    <span className="unshare-error-meta-details">
                        {requestId}:{traceId} ({timestamp})
                    </span>
                </div>
            </div>
        );
    }

    handleOnLimitChange = (value: number) => {
        const limit = value ? value : 10;

        this.props.dispatch(changeArchiveLimitAction(limit));

        this.handleOnPageChange(1); // Change to page 1 when on limit change
        this.getArchiveData({
            per_page: limit,
            page: 1,
            status: this.props.location.query.status || '',
        });
    };

    handleToggleMoveContainer = () =>
        this.setState({
            displayMoveContainer: !this.state.displayMoveContainer,
        });

    handleOnPreferred = (item: CaseFileItem) => {
        this.props.dispatch(setDefaultFolder(item.id));
        Analytics.track('archive - set default folder', {
            id: item.id,
            isSignersArchive: this.isSignersArchive,
        });
    };

    handleOnPageChange = (page: number) => {
        const {
            location: { pathname, query },
            router,
        } = this.props;

        const route = { pathname, query: { ...query, page } };

        window?.sessionStorage?.setItem(
            'archive-last-page',
            JSON.stringify(route)
        );
        router.push(route);
    };

    handleOnSortChange = (sort: string) => {
        const {
            location: { pathname },
            router,
        } = this.props;
        const route = {
            pathname,
            query: {
                ...this.props.location.query,
                sort,
            },
        };

        window?.sessionStorage?.setItem(
            'archive-last-page',
            JSON.stringify(route)
        );
        router.push(route);
    };

    handleOnMove: OnMove = async (
        newFolderId,
        oldFolderId,
        caseFilesIds,
        folders
    ) => {
        this.setState({ displayMoveContainer: false });
        const count = caseFilesIds.length + folders.length;

        if (newFolderId === oldFolderId) {
            return notify.show(
                <span>
                    {count === 1
                        ? i18n`The item is already in this folder`
                        : i18n`Those items are already in this folder`}
                </span>,
                'success',
                3000
            );
        }

        try {
            await this.props.dispatch(
                moveFoldersAndCaseFiles(
                    newFolderId,
                    oldFolderId,
                    caseFilesIds,
                    folders
                )
            );

            Analytics.track('archive - move items', {
                count: count,
                newFolder: newFolderId,
                oldFolder: oldFolderId,
                isSignersArchive: this.isSignersArchive,
            });

            this.getArchiveData();
            notify.show(
                <span>
                    {count === 1
                        ? i18n`Moved 1 item`
                        : i18n`Moved ${count} items`}
                </span>,
                'success',
                3000
            );
        } catch {
            notify.show(
                <span>
                    {count === 1
                        ? i18n`Could not move item`
                        : i18n`Could not move ${count} items`}
                </span>,
                'error',
                10000
            );
        }
    };

    handleOnShare = (item: CaseFileItem) => () => {
        this.selectedFolderId = item.id;
        this.selectedFolderName = item.title;

        this.setState({ displayShareModal: true });
    };

    handleOnCancelShareModal = () =>
        this.setState(
            { displayShareModal: false },
            () => (this.selectedFolderId = null)
        );

    handleOnShareDone = (error) => {
        if (error) {
            return notify.show(
                <span>{i18n(error.data.message)}</span>,
                'error',
                30000
            );
        }

        this.handleOnCancelShareModal();
        notify.show(
            <span>{i18n('Shared folder successfully')}</span>,
            'success',
            3000
        );
    };

    applySearch = () => {
        if (this.searchTimeOut) {
            clearTimeout(this.searchTimeOut);
        }

        this.searchTimeOut = setTimeout(() => {
            this.handleOnPageChange(1); // Change to page 1 when applying search terms
            this.getArchiveData();
        }, 500);
    };

    handleOnSearch = (search: string) => {
        window?.sessionStorage?.setItem('archive-search', search);
        this.setState({ search });
    };

    clearSearch = () => {
        window?.sessionStorage?.setItem('archive-search', '');
        this.setState({ search: '' });
    };

    handleOnDeselectAllCheckbox = (checked: boolean) => {
        const { items, sharedFolders, tab } = this.props;
        let selectedItems: Record<string, boolean> = {};

        if (checked) {
            const activeItems = tab === 'shared' ? sharedFolders : items;

            (activeItems as (CaseFileItem | SimpleFolderEntity)[])
                .filter((item) =>
                    this.userCanEditItem(
                        item.accessLevel,
                        item.userId,
                        item.shared,
                        this.state.folderOwnerId
                    )
                )
                .forEach((item) => {
                    selectedItems[caseFilesItemIdPrefix(item)] = true;
                });
        }

        this.setState({ selectedItems });
    };

    sortedItems = (
        items: CaseFileItem[] | SimpleFolderEntity[],
        sort: string
    ) => {
        // We always separate folders and files
        const breakdown: CaseFileItem[][] = lodash.partition(items, {
            type: 'folder',
        });

        //Then we sort alphabetically
        const order = sort === '-title' ? 'desc' : 'asc';
        const sorting = (segment: CaseFileItem[]) =>
            lodash.orderBy(
                segment,
                [(item: CaseFileItem) => item.title.toLowerCase()],
                order
            );

        /**
         * Notice that folders will always be sorted alphabetically.
         * Files won't, unless user is sorting by title.
         */
        const sorted = [
            sorting(breakdown[0]),
            ['title', '-title'].includes(sort)
                ? sorting(breakdown[1])
                : breakdown[1],
        ];

        return [...sorted[0], ...sorted[1]];
    };

    userCanEditItem = (
        itemAccessLevel:
            | typeof ACCESS_FULL
            | typeof ACCESS_READONLY
            | typeof ACCESS_READ_WRITE,
        itemOwnerId?: number,
        itemShared?: boolean,
        folderOwnerId?: number
    ) => {
        // If user is the owner, they can always edit
        if (
            (itemOwnerId && itemOwnerId === UserStore.getCurrentUser().id) ||
            (folderOwnerId && folderOwnerId === UserStore.getCurrentUser().id)
        ) {
            return true;
        }

        // For signers archive, check if any predecessor is shared (for case files: this.hasSharedPredecessors) or if the folder is shared (itemShared)
        if (this.isSignersArchive) {
            if (itemShared || this.hasSharedPredecessors) {
                // If folder is shared, only allow edit for READ_WRITE or FULL access
                return (
                    itemAccessLevel === ACCESS_READ_WRITE ||
                    itemAccessLevel === ACCESS_FULL
                );
            } else {
                // If folder is not shared, allow edit even for READ_ONLY access
                return true;
            }
        }

        // For non-signers archive, use default permission logic
        return (
            itemAccessLevel === ACCESS_READ_WRITE ||
            itemAccessLevel === ACCESS_FULL
        );
    };

    handleHideBanner() {
        localStorage.setItem(StorageKeys.HIDE_ARCHIVE_BANNNER, 'true');
        this.setState({ showBanner: false });
    }

    render() {
        const {
            modal,
            items,
            itemCount,
            sharedFolders,
            sharedFoldersCount,
            isLoading,
            location,
            folderId,
            archiveLimitItems,
            tab,
        } = this.props;
        const {
            displayMoveContainer,
            selectedItems,
            search,
            isSharedFolder,
            showBanner,
        } = this.state;
        const activeRoute = this.getActiveRoute();
        const isSharedRouteActive = tab === 'shared';
        const isAllRouteActive =
            activeRoute.name === this.props.routeNames.allRoute;

        // If in the root folder, only use columns relevant for folders
        const tableConfig = (() => {
            if (
                !folderId &&
                (activeRoute.name === 'archive-all' ||
                    activeRoute.name === 'personal-archive-all' ||
                    isSharedRouteActive)
            ) {
                return this.getTopLevelTableConfig();
            }

            return this.isSignersArchive
                ? this.getSignersArchiveTableConfig()
                : this.getExtendedTableConfig();
        })();

        const virtualTabParams = this.getVirtualFolderParams();
        const sort = virtualTabParams
            ? location.query.sort || virtualTabParams.sort || defaultSort
            : location.query.sort || defaultSort;

        const sortedItems = this.sortedItems(
            isSharedRouteActive ? sharedFolders : items,
            sort
        );
        // Shared folders count is not part of the original tabs, so we need to push it into the old stats
        const statsWithShared = Object.assign({}, this.props.stats, {
            items: {
                ...this.props.stats.items,
                shared: { count: sharedFoldersCount },
            },
        });

        return (
            <div className="archive-container">
                {showBanner && this.isSignersArchive && (
                    <div className="mb-2 relative">
                        <UIBanner
                            variant="accent"
                            layout="vertical"
                            onCloseClick={() => this.handleHideBanner()}
                            closeButton={true}>
                            <span slot="titleText">
                                {i18n`archive.bannerTitle`}
                            </span>
                            <span slot="subtitleText">
                                {i18n`archive.bannerBody`}
                            </span>
                            <div slot="icon">
                                <RocketLaunchIcon className="h-6"></RocketLaunchIcon>
                            </div>
                        </UIBanner>
                    </div>
                )}
                <Helmet>
                    <title>{i18n`Manage case files`}</title>
                </Helmet>

                {modal && (
                    <div className="archive-container-modal">{modal}</div>
                )}

                {displayMoveContainer && (
                    <Move
                        onCancel={this.handleToggleMoveContainer}
                        selectedItemsIds={selectedItems}
                        currentFolderId={folderId}
                        items={sortedItems}
                        onMove={this.handleOnMove}
                    />
                )}

                <div className="white-container no-padding-container">
                    <h3 className="title">{i18n`Case files`}</h3>

                    <ArchiveTabs
                        stats={statsWithShared}
                        shared={isSharedFolder}>
                        <Table
                            limit={archiveLimitItems}
                            page={Number(location.query.page) || 1}
                            sort={sort || ''}
                            config={tableConfig}
                            dataSource={sortedItems}
                            dataCount={
                                isSharedRouteActive
                                    ? sharedFoldersCount
                                    : itemCount
                            }
                            extractId={caseFilesItemIdPrefix}
                            onSortChange={this.handleOnSortChange}
                            onLimitChange={this.handleOnLimitChange}
                            onNext={this.handleOnPageChange}
                            onPrev={this.handleOnPageChange}
                            onPageChange={this.handleOnPageChange}
                            isLoading={isLoading}
                            emptyTableComponent={
                                <EmptyTableBody
                                    itemCount={itemCount}
                                    clearSearch={this.clearSearch}
                                    search={this.state.search}
                                />
                            }
                            headComponent={
                                <div>
                                    <TopActions
                                        dataCount={
                                            isSharedRouteActive
                                                ? sharedFoldersCount
                                                : itemCount
                                        }
                                        searchValue={search}
                                        isAllRouteActive={isAllRouteActive}
                                        onSearchInputChange={
                                            this.handleOnSearch
                                        }
                                        clearSearch={this.clearSearch}
                                        onNewFolder={this.handleOnNewFolder}
                                        onDeleteItems={
                                            this.handleDeleteItemsModal
                                        }
                                        onMoveItems={
                                            this.handleToggleMoveContainer
                                        }
                                        selectedItemsLength={
                                            Object.keys(selectedItems).length
                                        }
                                    />
                                </div>
                            }
                            triggerClick
                        />
                    </ArchiveTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState, props: Props) => {
    return {
        stats: state.archive.stats,
        items: state.archive.items,
        itemCount: state.archive.itemCount,
        sharedFolders: state.archive.sharedFolders.folders,
        sharedFoldersCount: state.archive.sharedFolders.folderCount,
        isLoading: state.archive.isLoading,
        getArchiveDataError: state.archive.error,
        archiveLimitItems: state.preferences.archive.limit,
        ...(!!props.routeParams.folderId && {
            folderId: Number(props.routeParams.folderId),
        }),
        ...(!!props.routeParams.tab && { tab: props.routeParams.tab }),
    };
})(ArchiveWrapper);
